import { Button, Link, Modal } from '@increasecard/typed-components';
import { Column } from '../../../components/common/Column';
import { IconButton } from '../../../components/common/IconButton';
import { Row } from '../../../components/common/Row';
import { Subscription } from '../../../types';
import { useModal } from '../../../hooks/useModal';
import { useUpdateSubscription } from '../subscriptionQueries';
import { Autorenew } from '@styled-icons/material/Autorenew';
import { ApiErrorMessage } from '../../../components/ErrorMessage';
import { formatCurrency } from '../../../utils/currencyUtils';

export interface DesynchedAmountProps {
  subscription: Subscription;
}

export function DesynchedAmount({
  subscription,
}: DesynchedAmountProps): JSX.Element | null {
  const modal = useModal();
  const { mutate, error, isLoading, isSuccess, isIdle } =
    useUpdateSubscription();

  const syncSusbcriptionAmount = () => {
    mutate({ id: subscription.id, amount: 0 });
  };

  if (!subscription) return null;

  return (
    <>
      {!subscription.amount_synced_with_plan && (
        <Column margin="0" alignItems="center">
          <Row margin="0">
            <IconButton iconType="warning" height="16px" width="16px" />
            Precio establecido manualmente
          </Row>
          <Link style={{ color: 'inherit' }} as="button" onClick={modal.open}>
            Sincronizar con plan
          </Link>
        </Column>
      )}
      <Modal
        simple
        headerText="Sincronizar precio con plan"
        onClose={modal.close}
        cancelLabel={error || isSuccess ? 'Cerrar' : 'Cancelar'}
        visible={modal.isOpen}
        icon={<Autorenew size="48px" />}
        customFooter={
          <>
            {(isIdle || !isSuccess) && (
              <Button type="button" variant="invisible" onClick={modal.close}>
                Cancelar
              </Button>
            )}
            <Button
              type="button"
              isLoading={isLoading}
              onClick={
                !error && !isSuccess ? syncSusbcriptionAmount : modal.close
              }
            >
              {isSuccess || error ? 'Aceptar' : 'Sincronizar'}
            </Button>
          </>
        }
      >
        {(isIdle || isLoading) && (
          <>
            El precio de esta suscripción está establecido manualmente.
            <br /> ¿Desea sincronizarlo con el precio base del plan (
            {formatCurrency(
              subscription.plan.amount,
              subscription.plan.currency
            )}
            ) ?
          </>
        )}
        {isSuccess &&
          'El precio de la suscripción se sincronizó correctamente.'}
        {error && <ApiErrorMessage error={error} />}
      </Modal>
    </>
  );
}
