/* eslint-disable react/prop-types */
import styled from 'styled-components';
import { Help } from '@styled-icons/material/Help';
import { Warning } from '@styled-icons/material/Warning';
import { forwardRef } from 'react';

export interface IconButtonProps {
  iconType: 'warning' | 'help';
  color?: 'gray' | 'white';
  width?: string;
  height?: string;
  onClick?: () => void;
}

const Button = styled.button`
  padding: 0;
  margin: 0 5px;
  position: relative;
  cursor: pointer;
`;

const StyledHelpIcon = styled(Help)<{
  color?: string;
  width?: string;
  height?: string;
}>`
  display: flex; /* This style removes a space that appears at the bottom */
  width: ${({ width = '12px' }) => width};
  height: ${({ height = '12px' }) => height};
  fill: ${({ color = 'gray' }) =>
    color === 'gray'
      ? `var(--color-gray-grayMedium)`
      : `var(--color-gray-grayMedium)`};
  &:hover {
    fill: ${({ color = 'gray' }) =>
      color === 'gray'
        ? `var(--color-gray-blackSLight)`
        : `var(--color-gray-white)`};
  }
`;

const StyledWarningIcon = styled(Warning)<{
  width?: string;
  height?: string;
}>`
  display: flex; /* This style removes a space that appears at the bottom */
  width: ${({ width = '12px' }) => width};
  height: ${({ height = '12px' }) => height};
  fill: var(--color-count-regular);
  &:hover {
    fill: var(--color-count-dark50);
  }
`;

function Icon({ iconType, ...props }: IconButtonProps): JSX.Element {
  switch (iconType) {
    case 'warning':
      return <StyledWarningIcon {...props} />;
    case 'help':
      return <StyledHelpIcon {...props} />;
  }
}

// eslint-disable-next-line react/display-name
export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  ({ width, height, iconType, color, ...props }, ref) => (
    <Button ref={ref} {...props} type="button">
      <Icon width={width} height={height} iconType={iconType} color={color} />
    </Button>
  )
);
