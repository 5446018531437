import { Warning } from '@styled-icons/material/Warning';
import {
  Button,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalHeaderIconBox,
} from '@increasecard/typed-components';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { ApiErrorMessage } from '../../../components/ErrorMessage';
import { Customer } from '../../../types/Customer';
import { useDeleteCustomer } from '../customerQueries';

export interface ConfirmDeleteModalProps {
  onClose: VoidFunction;
  visible: boolean;
  customer: Customer | null;
  redirectOnSuccess?: boolean;
}

const noop = () => {
  return false;
};

export function ConfirmDeleteModal({
  onClose,
  visible,
  customer,
  redirectOnSuccess,
}: ConfirmDeleteModalProps): JSX.Element {
  const { t } = useTranslation();
  const history = useHistory();
  const { mutate, isSuccess, isError, error, isLoading, reset } =
    useDeleteCustomer();

  const handleDelete = () => {
    if (customer) {
      mutate(customer);
    }
  };

  const handleClose = () => {
    if (isSuccess && redirectOnSuccess) {
      history.replace('/customers');
    } else {
      reset();
      onClose();
    }
  };

  return (
    <Modal
      onClose={handleClose}
      visible={visible}
      onOk={noop}
      onCancel={noop}
      shadeClosable={false}
    >
      <ModalHeaderIconBox colorPalette="alert" icon={<Warning size="48px" />} />
      <ModalHeader align="center">
        {t(`screens.customers.delete_modal_header`)}
      </ModalHeader>
      <ModalContent align="center">
        {isSuccess
          ? t('screens.customers.delete_modal_success')
          : t('screens.customers.delete_modal_message')}
        <ApiErrorMessage error={error} centered />
      </ModalContent>
      <ModalFooter align="center">
        {isError || isSuccess ? (
          <Button onClick={handleClose} buttonType="primary">
            {isSuccess && redirectOnSuccess
              ? t('common.goto_list')
              : t('common.close')}
          </Button>
        ) : (
          <>
            <Button
              data-testid="cancel-button"
              onClick={handleClose}
              buttonType="invisible"
              disabled={isLoading}
            >
              {t('common.cancel')}
            </Button>
            <Button
              data-testid="confirm-button"
              onClick={handleDelete}
              variant="alert"
              isLoading={isLoading}
            >
              {t('common.delete')}
            </Button>
          </>
        )}
      </ModalFooter>
    </Modal>
  );
}
