/* FIXME(santi698): This eslint disable will be hard to fix */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { ChangeEvent, createRef, useState } from 'react';
import styled from 'styled-components';
import { useFormik } from 'formik';
import NumberFormat from 'react-number-format';
import { useTranslation } from 'react-i18next';
import {
  InputText,
  NativeSelect,
  Spinner,
} from '@increasecard/typed-components';
import { MPCreditCardSchema } from '../CreditCardSchema';
import { ResponsiveGrid } from '../../common/ResponsiveGrid';
import { CreditCardInput } from '../CreditCardInput';
import {
  useUserCardInfo,
  createCardToken,
  useGetSupportedCards,
  PAYMENT_TYPE_ID,
} from './MpApiUtils';
import { PaymentMethodInfoBox } from '../PaymentMethodInfoBox';
import { OnlyDesktop, useResponsive } from '../../../hooks/useResponsive';
import { PaymentFormFooter } from '../PaymentFormFooter';
import { IDENTIFICATION_NUMBER_TYPES } from '../../../localizations';
import { Row } from '../../common/Row';
import { PaymentFormBase } from '../types';
import { MercadopagoConnectCollectMethod, NewApiError } from '../../../types';
import { useLoadGateway } from '../GatewayHOCs';

const INITIAL_FORM_VALUES = {
  name: '',
  cardNumber: '',
  securityCode: '',
  dni: '',
  document_type: '',
  expirationDate: '',
  set_as_default: false,
};

const getBin = (cardNumber: string) => cardNumber.substring(0, 6);

const HiddenInput = styled.input`
  display: none;
`;

export type MercadopagoFormProps = PaymentFormBase<
  MercadopagoConnectCollectMethod,
  Metadata
>;

export function MPForm({
  onSubmit,
  onCancel,
  processing,
  collectMethods,
  submitText,
  cancelText,
  isUniquePayment,
  termsAndConditionsUrl,
  errorObj,
  showSetAsDefaultPM,
  country,
}: MercadopagoFormProps): JSX.Element {
  const { t } = useTranslation();
  const formRef = createRef<HTMLFormElement>();
  const { isMobile } = useResponsive();
  const [localError, setLocalError] = useState<NewApiError | null>(null);

  const identificationTypes = IDENTIFICATION_NUMBER_TYPES[country];

  const handleSubmit = async (values: Metadata) => {
    const data = await createCardToken(
      formRef.current,
      processingMode,
      //agreement,
      collectMethods,
      setLocalError
    );

    const detail = paymentMethodDetail as Metadata;

    if (data) {
      onSubmit({
        ...data,
        card_brand_id: detail.id,
        card_brand_name: detail.name,
        card_brand_payment_type_id: detail.payment_type_id,
        device_id: window.MP_DEVICE_SESSION_ID,
        set_as_default: values.set_as_default,
      });
    }
  };

  const formik = useFormik({
    initialValues: {
      ...INITIAL_FORM_VALUES,
      document_type: identificationTypes ? identificationTypes[0].id : '',
    },
    enableReinitialize: true,
    validate: (values: Metadata) => {
      if (values.cardNumber && error) {
        const key = `validation.${error}`; // Weird typescript error
        return { cardNumber: t(key) };
      }
      return {};
    },
    validationSchema: MPCreditCardSchema(t),
    onSubmit: handleSubmit,
  });

  const {
    error,
    //agreement,
    processingMode,
    paymentMethodDetail,
  } = useUserCardInfo(
    getBin(formik.values.cardNumber),
    collectMethods,
    isUniquePayment
  );

  const supportedCards = useGetSupportedCards(collectMethods);

  const infoBoxData = {
    supportedCards: supportedCards,
    cardNumber: formik.values.cardNumber,
    name: formik.values.name,
    expirationDate: formik.values.expirationDate,
    cardBrandId: (paymentMethodDetail as Metadata).id,
  };

  const handleNumericChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!/\D/.test(e.target.value)) {
      formik.handleChange(e);
    }
  };

  const handleDniChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (formik.values.document_type !== 'Otro') {
      if (!/\D/.test(e.target.value)) {
        formik.handleChange(e);
      }
    } else {
      formik.handleChange(e);
    }
  };

  const handleChange = async (value: string) => {
    await formik.setFieldValue('cardNumber', value);
    if (value.length > 5) {
      formik.setFieldTouched('cardNumber', true);
    }
  };

  const expirationDateLabel = isMobile
    ? t('data.payment_method.expiration_date_long')
    : t('data.payment_method.expiration_date');
  const securityCodeLabel = isMobile
    ? t('data.payment_method.security_code_cvv_cvc')
    : t('data.payment_method.cvv_cvc');
  // With type="tel" numerical keyboard shos up in mobile devices
  return (
    <Row position="relative" gap="1.5rem" flexWrap="wrap">
      <ResponsiveGrid
        as="form"
        ref={formRef}
        rowGap={2}
        desktopMaxWidth="335px"
      >
        <CreditCardInput
          className="grid-span-desktop-8"
          cardBrand={(paymentMethodDetail as Metadata).name}
          showMethodIcon={isMobile}
          showHelpIcon={!!(isMobile && supportedCards)}
          supportedCards={supportedCards}
          errorMessage={formik.touched.cardNumber && formik.errors.cardNumber}
          onBlur={formik.handleBlur}
          value={formik.values.cardNumber}
          onChange={handleChange}
          id="cardNumber"
          data-checkout="cardNumber"
        />
        <NumberFormat
          className="grid-span-desktop-4"
          id="expirationDate"
          data-checkout="cardExpiration"
          type="tel"
          onpaste="return false"
          oncopy="return false"
          oncut="return false"
          ondrag="return false"
          ondrop="return false"
          autocomplete="off"
          required
          label={expirationDateLabel}
          placeholder={t('data.payment_method.exp_date_placeholder')}
          errorMessage={
            formik.touched.expirationDate && formik.errors.expirationDate
          }
          marginRight="1rem"
          onBlur={formik.handleBlur}
          value={formik.values.expirationDate}
          customInput={InputText}
          onValueChange={(formattedValues) => {
            formik.setFieldValue(
              'expirationDate',
              formattedValues.formattedValue
            );
          }}
          format="##/##"
        />
        <InputText
          className="grid-span-desktop-8"
          errorMessage={formik.touched.name && formik.errors.name}
          id="name"
          autoComplete="off"
          value={formik.values.name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          data-checkout="cardholderName"
          label={t('data.payment_method.name_on_card')}
          placeholder={t('data.payment_method.cardholder_name_placeholder')}
          required
        />
        {/* securityCode input is uncontrolled so de value does not end up in the html */}
        <InputText
          className="grid-span-desktop-4"
          errorMessage={
            formik.touched.securityCode && formik.errors.securityCode
          }
          id="securityCode"
          data-checkout="securityCode"
          type="tel"
          max="4"
          min="3"
          onpaste="return false"
          oncopy="return false"
          oncut="return false"
          ondrag="return false"
          ondrop="return false"
          autocomplete="off"
          onChange={handleNumericChange}
          onBlur={formik.handleBlur}
          label={securityCodeLabel}
          placeholder={t('data.payment_method.cvv_cvc_placeholder')}
          required
        />
        <NativeSelect
          id="document_type"
          className="grid-span-desktop-5"
          onChange={formik.handleChange}
          label={t('data.payment_method.document_type')}
        >
          {identificationTypes &&
            identificationTypes.map((opt, i) => (
              <option key={i} value={opt.id}>
                {opt.name}
              </option>
            ))}
        </NativeSelect>
        <HiddenInput
          id="docType"
          autoComplete="off"
          value={formik.values.document_type}
          readOnly={true}
          data-checkout="docType"
        />
        <InputText
          className="grid-span-desktop-7"
          errorMessage={formik.touched.dni && formik.errors.dni}
          id="dni"
          type="tel"
          autoComplete="off"
          value={formik.values.dni}
          onChange={handleDniChange}
          onBlur={formik.handleBlur}
          data-checkout="docNumber"
          label={t('data.payment_method.document_number')}
          placeholder={t('data.payment_method.document_number_placeholder')}
          required
        />
        <PaymentFormFooter
          className="grid-span-desktop-12"
          isSubmitting={formik.isSubmitting || processing}
          onSubmit={formik.handleSubmit}
          submitText={submitText}
          onCancel={onCancel}
          cancelText={cancelText}
          showCardDisclaimer={
            (paymentMethodDetail as Metadata).payment_type_id ===
            PAYMENT_TYPE_ID.CREDIT_CARD
          }
          termsAndConditionsUrl={termsAndConditionsUrl}
          onSetDefaultPaymentMethod={
            showSetAsDefaultPM ? formik.handleChange : undefined
          }
          error={errorObj}
        />
      </ResponsiveGrid>
      <OnlyDesktop>
        <PaymentMethodInfoBox
          type="card"
          data={infoBoxData}
          errorObj={errorObj || localError}
        />
      </OnlyDesktop>
    </Row>
  );
}

export function MercadopagoForm(props: MercadopagoFormProps): JSX.Element {
  const { collectMethods } = props;
  const { gatewayLoaded } = useLoadGateway('mercadopago_connect', {
    countryCode: collectMethods.country,
  });
  return !gatewayLoaded ? <Spinner size={24} /> : <MPForm {...props} />;
}
