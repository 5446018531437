import { useEffect, useRef, useState } from 'react';
import { loadMercadopagoSdk } from './mercadopago/MpApiClient';
import { loadDlocalSdk } from './dlocal/dlocalUtils';
import { loadDecidirSdk } from './decidir/decidirApiClient';
import { loadPaymentsOSSDK } from './paymentsos/paymentsosApiClient';
import { CardCollectMethod } from '../../types';

export const GATEWAYS = {
  MERCADOPAGO: 'mercadopago',
  DLOCAL: 'dlocal',
  DECIDIR: 'decidir',
  PAYMENTS_OS: 'payments_os',
};

export interface UseLoadGateway {
  gatewayLoaded: boolean;
  sdkInstance: Metadata | null;
}

export function useLoadGateway(
  gatewayName: CardCollectMethod['type'],
  metadata: Metadata = {}
): UseLoadGateway {
  const sdkRef = useRef<Metadata>(null);
  const [gatewayLoad, setGatewayLoad] = useState({
    loading: false,
    loaded: false,
  });

  useEffect(() => {
    if (!gatewayLoad.loading && !gatewayLoad.loaded) {
      setGatewayLoad({ loading: true, loaded: false });
      switch (gatewayName) {
        case 'mercadopago_connect':
          loadMercadopagoSdk(metadata.countryCode).then(() =>
            setGatewayLoad({ loading: false, loaded: true })
          );
          break;
        case 'dlocal':
        case 'dlocal_cross':
          loadDlocalSdk(metadata.apiKey).then(() =>
            setGatewayLoad({ loading: false, loaded: true })
          );
          break;
        case 'decidir':
          loadDecidirSdk(metadata.apiKey).then((instance) => {
            sdkRef.current = instance;
            setGatewayLoad({ loading: false, loaded: true });
          });
          break;
        case 'payments_os':
          loadPaymentsOSSDK(metadata.apiKey).then((sdkInstance) => {
            sdkRef.current = sdkInstance;
            setGatewayLoad({ loading: false, loaded: true });
          });
          break;
        default:
      }
    }
  }, [
    gatewayName,
    metadata.countryCode,
    metadata.apiKey,
    metadata,
    gatewayLoad,
  ]);

  return { gatewayLoaded: gatewayLoad.loaded, sdkInstance: sdkRef.current };
}
