import { useState, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { H1, Button, Paragraph } from '@increasecard/typed-components';
import { ModeEdit } from '@styled-icons/material/ModeEdit';
import { ChevronLeft } from '@styled-icons/material/ChevronLeft';
import { getCustomerName } from '../../utils/utils';
import { Row } from '../../components/common/Row';
import { InputSearchDropdown } from '../../components/common/InputSearchDropdown';
import { CustomerDetails } from './components/CustomerDetails';
import { Customer } from '../../types/Customer';
import { useCustomers } from './customerQueries';
import useDebounce from '../../hooks/useDebounce';
import { useCustomerById } from '../../globalQueries/Queries';

export interface CustomerSelectorProps {
  initialCustomerId?: string;
  onChange: (customer: Customer) => void;
  changeButtonHidden?: boolean;
  titleHidden?: boolean;
}

export function CustomerSelector({
  initialCustomerId,
  onChange,
  changeButtonHidden = false,
  titleHidden = false,
}: CustomerSelectorProps): JSX.Element {
  const { t } = useTranslation();
  const [search, setSearch] = useState<string>();
  const [showSearch, setShowSearch] = useState(!initialCustomerId);
  const customer = useCustomerById(initialCustomerId);
  const debouncedSearch = useDebounce(search, 300);
  const customers = useCustomers({
    page: showSearch ? 1 : 0,
    search: debouncedSearch,
  });

  const toggleShowSearch = () => {
    setShowSearch((value) => !value);
  };

  const onCustomerSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const handleSelectCustomer = (customer: Customer) => {
    setShowSearch(false);
    onChange(customer);
  };

  const changeCustomerButton = (
    <Button
      variant="invisible"
      type="button"
      icon={showSearch ? <ChevronLeft /> : <ModeEdit />}
      onClick={toggleShowSearch}
      size="small"
    >
      {showSearch ? t('common.back') : t('screens.customers.select_other')}
    </Button>
  );

  return (
    <>
      <Row
        margin="0 0 1rem 0"
        alignItems="center"
        justifyContent="space-between"
        maxWidth="335px"
      >
        {!titleHidden && <H1>{t('common.customer')}</H1>}
        {!initialCustomerId || changeButtonHidden ? null : changeCustomerButton}
      </Row>
      {customer.data && !showSearch ? (
        <CustomerDetails customer={customer.data} />
      ) : (
        <>
          <Paragraph>Buscar cliente</Paragraph>
          <InputSearchDropdown
            placeholder={t('screens.customers.search_customer')}
            onChange={onCustomerSearchChange}
            options={customers.data?.data || []}
            getOptionName={getCustomerName}
            onSelect={handleSelectCustomer}
          />
        </>
      )}
    </>
  );
}
