import styled from 'styled-components';

export const TabGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 56px;
  margin-bottom: 1rem;
  gap: 35px;
  a {
    text-decoration: none;
  }
`;
