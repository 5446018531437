import { useTranslation } from 'react-i18next';
import {
  Paragraph,
  Grid,
  Tag,
  CellText,
  Table,
} from '@increasecard/typed-components';
import styled from 'styled-components';
import { PaymentMethodLabel } from './PaymentMethodLabel';
import { PaymentMethod } from '../../types/PaymentMethod';
import { MouseEvent } from 'react';
import {
  useCollectMethods,
  useCustomerById,
} from '../../globalQueries/Queries';
import { GroupedCollectMethods } from '../../types';
import { filterPaymentMethodsByCollectMethods } from '../../utils/collectMethodsUtils';
import { TableSkeleton } from '../common/DataTable/TableSkeleton';
import { DeleteButton } from '../common/DeleteButton';
import { formatISODate } from '../../utils/dateUtils';

const PMContainer = styled.div<{ allowHover: boolean }>`
  display: grid;
  gap: 8px;
  align-items: center;
  grid-template-columns: 1fr auto;
  grid-template-areas:
    'pm date'
    'cm button';
  padding-bottom: 8px;
  border-bottom: 1px solid var(--color-gray-grayMedium);
  .pm-label {
    grid-area: pm;
  }
  .pm-date {
    justify-self: end;
    grid-area: date;
  }
  .cm-label {
    grid-area: cm;
  }
  .action-button {
    justify-self: end;
    grid-area: button;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    grid-template-columns: 1fr 1fr auto;
    grid-template-areas:
      'pm pm date'
      'cm cm button';
  }

  ${({ allowHover }) =>
    allowHover &&
    `cursor: pointer;
    &.selected, &:hover {
      border-bottom-color: var(--color-pay-regular);
    }`}
`;

function SelectedLabel({ isSelected }: { isSelected: boolean }): JSX.Element {
  const { t } = useTranslation();
  const mainColor = isSelected
    ? 'var(--color-pay-regular)'
    : 'var(--color-gray-grayBold)';
  return (
    <Tag
      colors={{
        foreground: mainColor,
        border: mainColor,
        background: 'var(--color-gray-white)',
      }}
    >
      {isSelected
        ? t('common.selected', { count: undefined })
        : t('common.use')}
    </Tag>
  );
}

export interface CustomerPaymentMethodsProps {
  customerId: string;
  onChange?: (paymentMethod: PaymentMethod) => void;
  onDelete?: (paymentMethod: PaymentMethod) => void;
  selectedPaymentMethodId?: string;
  groupedCollectMethods?: GroupedCollectMethods;
  noPaymentMethodsMsg?: string;
}

export function CustomerPaymentMethods({
  customerId,
  onChange,
  onDelete,
  selectedPaymentMethodId,
  groupedCollectMethods,
  noPaymentMethodsMsg,
}: CustomerPaymentMethodsProps): JSX.Element | null {
  const { t } = useTranslation();
  const { data: collectMethods } = useCollectMethods();
  const customer = useCustomerById(customerId);

  const onBoxClick = (paymentMethod: PaymentMethod) => {
    if (onChange) {
      onChange(paymentMethod);
    }
  };

  const handleDelete = (paymentMethod: PaymentMethod) => {
    return (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();

      if (onDelete) {
        onDelete(paymentMethod);
      }
    };
  };

  const getCMName = (collectMethodId: string) => {
    if (collectMethods) {
      const cm = collectMethods.find((cm) => cm.id === collectMethodId);
      return cm ? cm.name : '';
    }
    return '';
  };

  const isSelected = (paymentMethod: PaymentMethod) =>
    paymentMethod.id === selectedPaymentMethodId;

  if (!customer.data)
    return (
      <Table style={{ width: '100%' }}>
        <TableSkeleton rowsCount={2} columnsCount={2} />
      </Table>
    );

  const paymentMethods = filterPaymentMethodsByCollectMethods(
    groupedCollectMethods,
    customer.data.payment_methods
  );

  if (paymentMethods.length === 0)
    return (
      <Paragraph>
        {noPaymentMethodsMsg || t('common.no_payment_methods')}
      </Paragraph>
    );

  return (
    <Grid className="grid-span-desktop-12" rowGap={1}>
      {paymentMethods.map((paymentMethod: PaymentMethod) => (
        <PMContainer
          className={`${
            isSelected(paymentMethod) ? 'selected' : ''
          } grid-span-desktop-6`}
          onClick={() => onBoxClick(paymentMethod)}
          allowHover={!!onChange}
          key={paymentMethod.id}
          role="button"
        >
          <PaymentMethodLabel
            paymentMethod={paymentMethod}
            showFullDescription={true}
            className="pm-label"
            showValidationStatus={true}
          />
          <CellText className="pm-date">{`creado: ${formatISODate(
            paymentMethod.created_at
          )}`}</CellText>
          <div className="action-button">
            {onChange && (
              <SelectedLabel isSelected={isSelected(paymentMethod)} />
            )}
            {onDelete && <DeleteButton onClick={handleDelete(paymentMethod)} />}
          </div>
          <CellText className="cm-label">
            {getCMName(paymentMethod.collect_method_id)}
          </CellText>
        </PMContainer>
      ))}
    </Grid>
  );
}
