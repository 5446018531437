import { Table, TableWrapper } from '@increasecard/typed-components';
import styled from 'styled-components';

export const ResponsiveTable = styled(Table)`
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    table,
    thead,
    tbody,
    th,
    td {
      display: block;
    }

    thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }
    && tr {
      display: flex;
      flex-direction: column;
      gap: 12px;
      padding: 8px;
      height: auto;
    }
    td {
      height: auto;
      padding: 0;
      text-align: right;
    }
    td::before {
      /*
      * aria-label has no advantage, it won't be read inside a table
      content: attr(aria-label);
      */
      content: attr(data-label);
      float: left;
      font-weight: bold;
      text-transform: uppercase;
    }
    td .status-tag {
      display: inline-flex;
    }
  }
`;

export const ResponsiveTableWrapper = styled(TableWrapper)`
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    border-radius: 0;
    border: 0;
    border-bottom: 2px solid var(--color-gray-grayMedium);
    padding: 12px 0;
  }
`;
