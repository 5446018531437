import styled from 'styled-components';
import { Checkbox } from '@increasecard/typed-components';
import get from 'lodash/get';
import {
  SelectionRowCallback,
  ColumnDef,
  RowClickCallback,
} from './tableDefinitions';
import { Menu, MenuAction } from '../Menu';
import { formatISODate } from '../../../utils/dateUtils';
import { Tag } from '../Tag';
import { callIfFunction } from '../../../utils/utils';
import { useRowSelection } from './useRowSelection';
import { formatCurrency } from '../../../utils/currencyUtils';
import { CustomerNameAndMail } from '../CustomerNameAndMail';
import { PaymentMethodLabel } from '../../paymentMethods/PaymentMethodLabel';
import { useResponsive } from '../../../hooks/useResponsive';

export interface RowProps {
  id: string;
  row: Metadata;
  double?: boolean;
  border?: boolean;
  columns: ColumnDef[];
  menuActions?: MenuAction[];
  hideDisabledOptions?: boolean;
  onSelect?: SelectionRowCallback;
  isSelected?: boolean;
  onClick?: RowClickCallback;
}

const RowButton = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  opacity: 0.3;
  &:hover {
    background-color: var(--color-increase-light);
  }
`;

const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

const getField = (field: Metadata, fieldName: string) => {
  return fieldName ? get(field, fieldName) : field;
};

export function Row({
  id,
  row,
  double,
  border,
  columns,
  menuActions,
  hideDisabledOptions,
  onSelect,
  isSelected,
  onClick,
}: RowProps): JSX.Element {
  const { selectionEnabled, handleSelection } = useRowSelection(onSelect);
  const { isMobile } = useResponsive();

  const getCellContent = (column: ColumnDef, row: Metadata) => {
    const { field, valueGetter } = column;
    switch (column.type) {
      case 'date':
        return formatISODate(get(row, field), undefined, column.defaultText);
      case 'tag':
        return (
          <Tag
            className="status-tag"
            type={`${column.tagType}.status.${
              valueGetter ? valueGetter(row) : get(row, field)
            }`}
          >
            {callIfFunction(column.tagLabel, row)}
          </Tag>
        );
      case 'currency': {
        const currencyCode = column.currencyCode
          ? column.currencyCode
          : get(row, column.currencyField || 'currency');
        let value = get(row, field);
        value = column.disallowNegative
          ? Math.max(parseFloat(value), 0)
          : value;

        return formatCurrency(value, currencyCode);
      }
      case 'customer':
        return <CustomerNameAndMail customer={getField(row, field)} />;

      case 'payment_method':
        return (
          <PaymentMethodLabel
            paymentMethod={
              valueGetter ? valueGetter(row) : getField(row, field)
            }
            showValidationStatus={column.showValidationStatus}
          />
        );
      case 'string':
      default:
        return valueGetter ? valueGetter(row) : get(row, field);
    }
  };

  const shouldShowRowButton = (cellIndex: number, row: Metadata) => {
    if (cellIndex === 0 && onClick && !isSafari) {
      return <RowButton onClick={() => onClick(row)} />;
    }
  };

  const menuCell = menuActions ? (
    <td className="rightAligned">
      <Menu
        row={row}
        menuActions={menuActions}
        hideDisabledOptions={hideDisabledOptions}
        buttonProps={{
          style: { position: 'relative', zIndex: 2 },
        }}
      />
    </td>
  ) : null;

  return (
    <tr
      data-test-id={id}
      className={`${double ? 'double' : ''} ${border ? 'border' : ''}`}
      style={{ position: 'relative' }}
    >
      {selectionEnabled ? (
        <td>
          <Checkbox
            id="selectAll"
            name="selectAll"
            label="Seleccionar todos"
            labelHidden
            onChange={handleSelection([row])}
            checked={isSelected}
            style={{ zIndex: 2 }}
          />
        </td>
      ) : null}
      {isMobile && menuCell}
      {columns.map((col, index) => {
        const { field, rightAligned, width, ellipsis, hideOnMobile } = col;
        return (
          !hideOnMobile && (
            <td
              data-label={col.headerName}
              key={`td-${field}-${index}`}
              className={`${rightAligned ? 'rightAligned' : ''} ${
                !!width || ellipsis ? 'ellipsis' : ''
              }`}
            >
              {getCellContent(col, row)}
              {shouldShowRowButton(index, row)}
            </td>
          )
        );
      })}
      {!isMobile && menuCell}
    </tr>
  );
}
