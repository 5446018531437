import { Checkbox, InputLabel, Link } from '@increasecard/typed-components';
import { ChangeEvent } from 'react';
import { Column } from '../common/Column';
import { LabelError } from '../common/LabelError';
import { Row } from '../common/Row';

export interface AcceptTermsCheckboxProps {
  termsAndConditionsUrl?: string | null;
  onAcceptChange?: (accept: boolean) => void;
  errorMessage?: string;
}

export function AcceptTermsCheckbox({
  termsAndConditionsUrl,
  onAcceptChange,
  errorMessage,
}: AcceptTermsCheckboxProps): JSX.Element | null {
  const handleAcceptChange = (e: ChangeEvent<HTMLInputElement>) => {
    onAcceptChange && onAcceptChange(e.target.checked);
  };
  return termsAndConditionsUrl ? (
    <Column margin="0" gap="3px">
      <Row margin="0" alignItems="center">
        <Checkbox
          id="acceptTermsAndConditions"
          onChange={handleAcceptChange}
          labelHidden={true}
          label="label"
          required
        />
        <InputLabel>
          Acepto los{' '}
          <Link href={termsAndConditionsUrl} target="_blank">
            terminos y condiciones
          </Link>
        </InputLabel>
      </Row>
      {errorMessage && <LabelError>{errorMessage}</LabelError>}
    </Column>
  ) : null;
}
