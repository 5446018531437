import { ChangeEvent, FormEvent, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  InputText,
  NativeSelect,
  Spinner,
} from '@increasecard/typed-components';
import { ResponsiveGrid } from '../../common/ResponsiveGrid';
import { Row } from '../../common/Row';
import { useLoadGateway } from '../GatewayHOCs';
import { OnlyDesktop, useResponsive } from '../../../hooks/useResponsive';
import { PaymentsOSField } from './PaymentsOSField';
import { Constants } from '../../../constants';
import { PaymentMethodInfoBox } from '../PaymentMethodInfoBox';
import { PaymentFormFooter } from '../PaymentFormFooter';
import { usePayUFingerprint } from './providerUtils/payuUtils';
import { PAYMENTS_OS_ID_NUMBER_TYPES, COUNTRIES } from '../../../localizations';
import { CountryFilter } from '../../common/CountryFilter';
import { usePOSForm } from './usePOSForm';
import { NewApiError, PayUCollectMethod } from '../../../types';
import { FieldsInstance, FieldType, POSWrapper } from './paymentsosApiClient';
import { PaymentFormBase } from '../types';

const baseInputStyle = {
  fontSize: '13px',
  fontFamily: '"Inter var", Inter, sans-serif',
  lineHeight: '16px',
  letterSpacing: '-0.003rem',
  fontSmoothing: 'antialiased',
  fontWeight: '500',
  color: '#182026',
  '::placeholder': {
    color: '#b1b1b1',
  },
  iconStyle: 'solid',
};

const createPaymentsOSField = (
  fieldsInstance: FieldsInstance,
  fieldType: FieldType,
  options = {}
) => {
  return fieldsInstance.create(fieldType, {
    ...options,
    style: {
      base: baseInputStyle,
    },
  });
};

export type PaymentsOSFormProps = PaymentFormBase<PayUCollectMethod, Metadata>;
interface POSFormProps extends PaymentsOSFormProps {
  sdkInstance: POSWrapper;
}

function POSForm({
  onSubmit,
  onCancel,
  processing,
  submitText,
  cancelText,
  collectMethods,
  sdkInstance,
  customerId,
  country,
  isUniquePayment,
  termsAndConditionsUrl,
  showSetAsDefaultPM,
  errorObj,
}: POSFormProps): JSX.Element {
  const { t } = useTranslation();
  const { isMobile } = useResponsive();
  const fingerprint = usePayUFingerprint(customerId);
  const [paymentsOSError, setPaymentsOSError] = useState<NewApiError | null>(
    null
  );
  const [isSubmitting, setIsSubmitting] = useState(false);

  const idTypeOptions = PAYMENTS_OS_ID_NUMBER_TYPES[country];

  const fieldsInstance = useMemo(
    () =>
      sdkInstance.createFields({
        fonts: [
          {
            src: 'https://rsms.me/inter/inter-ui.css',
          },
        ],
        placeholders: {
          cardNumber: t('data.payment_method.card_placeholder'),
          cvv: t('data.payment_method.cvv_cvc_placeholder'),
          expDate: t('data.payment_method.exp_date_placeholder'),
        },
      }),
    [sdkInstance, t]
  );
  const cardNumber = useMemo(
    () => createPaymentsOSField(fieldsInstance, 'cardNumber'),
    [fieldsInstance]
  );
  const expiration = useMemo(
    () => createPaymentsOSField(fieldsInstance, 'creditCardExpiry'),
    [fieldsInstance]
  );
  const cvv = useMemo(
    () => createPaymentsOSField(fieldsInstance, 'cvv'),
    [fieldsInstance]
  );

  const { formState, setFormValue, handlePosFieldUpdate, isValid } = usePOSForm(
    {
      country,
      isUniquePayment,
    }
  );

  const handleCardHolderNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFormValue({ type: 'cardHolderName', payload: e.target.value });
  };

  const handleIdentityDocumentChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (formState.identityDocument.type !== 'Otro') {
      if (!/\D/.test(e.target.value)) {
        setFormValue({
          type: 'identityDocumentNumber',
          payload: e.target.value,
        });
      }
    } else {
      setFormValue({
        type: 'identityDocumentNumber',
        payload: e.target.value,
      });
    }
  };

  const handleIdentityDocumentTypeChange = (
    e: ChangeEvent<HTMLSelectElement>
  ) => {
    setFormValue({
      type: 'identityDocumentNumber',
      payload: e.target.value,
    });
  };

  const handleSetAsDefault = (e: ChangeEvent<HTMLInputElement>) => {
    setFormValue({
      type: 'setAsDefault',
      payload: e.target.checked,
    });
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    // If aditional data wasn't filled the submit is canceled and
    // the browser validations shows up
    if (
      !formState.cardHolderName ||
      (country === COUNTRIES.ARGENTINA && !formState.identityDocument.number)
    ) {
      return;
    }

    e.preventDefault();
    if (isValid) {
      setIsSubmitting(true);

      try {
        const aditionalData = {
          holder_name: formState.cardHolderName,
        };
        // If identity_document is tokenized with empty value
        // they return it as empty object in the token info object
        if (country === COUNTRIES.ARGENTINA) {
          Object.assign(aditionalData, {
            identity_document: formState.identityDocument,
          });
        }
        const tokenInfo = await sdkInstance.createToken(
          cardNumber,
          aditionalData
        );

        setPaymentsOSError(null);

        const paymentsOS = collectMethods;
        // TODO: generate and send fingerprint only when provider is PayULatam
        onSubmit({
          ...tokenInfo,
          type: Constants.CollectMethods.CARD.PAYMENTS_OS,
          collect_method_id: paymentsOS.id,
          set_as_default: formState.set_as_default,
          fingerprint,
        });
      } catch (error: Metadata) {
        // TODO: improve error treatment
        setPaymentsOSError(
          NewApiError.fromAPI({ code: 'PAYU_ERROR', message: error.message })
        );
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  const expirationDateLabel = isMobile
    ? t('data.payment_method.expiration_date_long')
    : t('data.payment_method.expiration_date');
  const securityCodeLabel = isMobile
    ? t('data.payment_method.security_code_cvv_cvc')
    : t('data.payment_method.cvv_cvc');

  const infoBoxData = {
    supportedCards: null,
    cardNumber: '',
    name: formState.cardHolderName,
    expirationDate: '',
    cardBrandId: formState.cardBrand,
  };

  const error = paymentsOSError || errorObj;

  return (
    <Row position="relative" gap="1.5rem" flexWrap="wrap">
      <ResponsiveGrid as="form" rowGap={2} desktopMaxWidth="335px">
        <PaymentsOSField
          field={cardNumber}
          type="cardNumber"
          label={t('data.payment_method.card_number')}
          className="grid-span-desktop-8"
          onChange={handlePosFieldUpdate}
          onBlur={handlePosFieldUpdate}
          errorMessage={
            formState.cardNumber.error &&
            t(`validation.${formState.cardNumber.error}`)
          }
        />
        <PaymentsOSField
          field={expiration}
          type="expiration"
          label={expirationDateLabel}
          className="grid-span-desktop-4"
          onChange={handlePosFieldUpdate}
          onBlur={handlePosFieldUpdate}
          errorMessage={
            formState.expiration.error &&
            t(`validation.${formState.expiration.error}`)
          }
        />
        <InputText
          id="name"
          label={t('data.payment_method.name_on_card')}
          placeholder={t('data.payment_method.cardholder_name_placeholder')}
          onChange={handleCardHolderNameChange}
          required
          autoComplete="name"
          className="grid-span-desktop-8"
        />
        <PaymentsOSField
          field={cvv}
          type="cvv"
          label={securityCodeLabel}
          className="grid-span-desktop-4"
          onChange={handlePosFieldUpdate}
          onBlur={handlePosFieldUpdate}
          errorMessage={
            formState.cvv.error && t(`validation.${formState.cvv.error}`)
          }
        />
        <CountryFilter showFor={[COUNTRIES.ARGENTINA]} currentCountry={country}>
          <NativeSelect
            id="document_type"
            className="grid-span-desktop-5"
            onChange={handleIdentityDocumentTypeChange}
            label={t('data.payment_method.document_type')}
          >
            {idTypeOptions &&
              idTypeOptions.map((opt, i) => (
                <option key={i} value={opt.id}>
                  {opt.name}
                </option>
              ))}
          </NativeSelect>
          <InputText
            className="grid-span-desktop-7"
            id="identity-number"
            autoComplete="off"
            onChange={handleIdentityDocumentChange}
            value={formState.identityDocument.number}
            label={t('data.payment_method.document_number')}
            placeholder={t('data.payment_method.document_number_placeholder')}
            required
          />
        </CountryFilter>
        <PaymentFormFooter
          className="grid-span-desktop-12"
          isSubmitting={isSubmitting || processing}
          onSubmit={handleSubmit}
          submitText={submitText}
          onCancel={onCancel}
          cancelText={cancelText}
          showCardDisclaimer={true}
          termsAndConditionsUrl={termsAndConditionsUrl}
          onSetDefaultPaymentMethod={
            showSetAsDefaultPM ? handleSetAsDefault : undefined
          }
          error={error}
        />
      </ResponsiveGrid>
      <OnlyDesktop>
        <PaymentMethodInfoBox type="card" data={infoBoxData} errorObj={error} />
      </OnlyDesktop>
    </Row>
  );
}

export function PaymentsOSForm(
  props: PaymentFormBase<PayUCollectMethod>
): JSX.Element {
  const collectMethod = props.collectMethods; // Should be only one CM
  const { gatewayLoaded, sdkInstance } = useLoadGateway('payments_os', {
    apiKey: collectMethod.metadata.public_key,
    customerId: props.customerId,
  });
  return gatewayLoaded && sdkInstance ? (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <POSForm {...props} sdkInstance={sdkInstance as any} />
  ) : (
    <Spinner size={24} />
  );
}
