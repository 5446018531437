import { CSSProperties } from 'react';
import styled from 'styled-components';
import { Help } from '@styled-icons/material/Help';

const Wrapper = styled.div<{
  position: Pick<CSSProperties, 'top' | 'right' | 'bottom' | 'left'>;
}>`
  position: absolute;
  ${({ position }) => position}
  padding: 4px;
`;

export const StyledHelpIcon = styled(Help)<{
  color?: string;
  width?: string;
  height?: string;
}>`
  cursor: pointer;
  width: ${({ width = '1.2em' }) => width};
  height: ${({ height = '1.2em' }) => height};
  fill: ${({ color = 'gray' }) =>
    color === 'gray'
      ? `var(--color-gray-grayMedium)`
      : `var(--color-pay-light)`};
  &:hover {
    fill: ${({ color = 'gray' }) =>
      color === 'gray'
        ? `var(--color-increase-light50)`
        : `var(--color-gray-white)`};
  }
`;

export type HelpIconProps = typeof Wrapper.arguments;

export function HelpIcon(props: HelpIconProps): JSX.Element {
  return (
    <Wrapper {...props}>
      <StyledHelpIcon />
    </Wrapper>
  );
}
