import { useLayoutEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Schedule } from '@styled-icons/material/Schedule';
import {
  ModalHeaderIconBox,
  Paragraph,
  UniversalPortal,
  H2,
} from '@increasecard/typed-components';
import { Box } from '../common/Box';
import { ProgressBox } from '../common/ProgressBox';
import { ImportedFile, ImportType } from '../../types/ImportFile';

const Shade = styled.div<{ leftOffset?: number }>`
  position: absolute;
  width: 100%;
  margin-left: -1rem;
  height: 100%;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    width: calc(100vw - 1rem - ${({ leftOffset }) => leftOffset}px);
  }
`;

const Modal = styled.div`
  border-radius: 4px;
  background-color: white;
  max-width: 600px;
  margin: 15vh auto;
`;

export function FileProcessing({
  jobInfo,
}: {
  jobInfo: ImportedFile;
}): JSX.Element | null {
  const { t } = useTranslation();
  //Workaround to get the correct offset wifth any possible top notification included.
  const [leftOffset, setLeftOffset] = useState(112);
  useLayoutEffect(() => {
    const node = document.getElementById('left-nav-bar');
    if (node) {
      setLeftOffset(node.getBoundingClientRect().width);
    }
  }, []);

  const entity = jobInfo.type.split('_')[0];
  const title =
    jobInfo.type === ImportType.SubscriptionUpdateAmount
      ? t('screens.import.update_in_progress', {
          entity: t('common.subscriptions'),
        })
      : t('screens.import.creation_in_progress', {
          entity: t(`common.${entity}`),
        });

  return (
    <UniversalPortal selector="#main-layout">
      <Shade leftOffset={leftOffset}>
        <Modal>
          <ModalHeaderIconBox
            colorPalette="pay"
            icon={<Schedule size="48px" />}
          />
          <Box textAlign="center" display="grid" gap="1rem" padding="1rem">
            <H2>{title}</H2>
            <Paragraph>
              {t('screens.import.file_in_progress', {
                name: jobInfo.name,
              })}
            </Paragraph>
            <ProgressBox
              value={jobInfo.processed_lines}
              max={jobInfo.total_lines}
              message={t('screens.import.file_progress', {
                processedLines: jobInfo.processed_lines,
                totalLines: jobInfo.total_lines,
              })}
            />
            <Paragraph>
              <Trans i18nKey="screens.import.process_disclaimer" />
            </Paragraph>
          </Box>
        </Modal>
      </Shade>
    </UniversalPortal>
  );
}
