import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { MenuActionDefinition } from '../../../components/common/DataTable/DataTable';
import { useClipboard } from '../../../hooks/useClipboard';
import { useModal, ModalControls } from '../../../hooks/useModal';
import { useUIEventTracker } from '../../../hooks/useUIEventTracker';
import { Subscription } from '../../../types/Subscription';
import {
  canChangeBillingDate,
  isActivable,
  isApprovable,
  isInactionable,
  isPausable,
  isRejectable,
} from '../../../utils/subscriptionUtils';
import { usePayAuthorizations } from '../../authorizations/usePayAuthorizations';
import { SubscriptionActionTypes } from './ActionsModal';

export interface UseSubscriptionActions {
  actions: MenuActionDefinition[];
  actionsModal: ModalControls<{
    subscription: Subscription[];
    actionType: SubscriptionActionTypes;
  }>;
  paymentMethodModal: ModalControls<Subscription>;
}

export type MenuOptionType =
  | SubscriptionActionTypes
  | 'edit'
  | 'view_details'
  | 'copy_checkout_link'
  | 'change_payment_method';

export interface SubscriptionActionDefinition extends MenuActionDefinition {
  actionType: MenuOptionType;
}

export function useSubscriptionActions(
  context: string,
  omit?: MenuOptionType[]
): UseSubscriptionActions {
  const { t } = useTranslation();
  const history = useHistory();
  const actionsModal = useModal<{
    subscription: Subscription[];
    actionType: SubscriptionActionTypes;
  }>(false);
  const paymentMethodModal = useModal<Subscription>();
  const { canManageSubscriptions, canApproveSubscription } =
    usePayAuthorizations();
  const clipboard = useClipboard();
  const trackEvent = useUIEventTracker(context);

  const getChangePaymentLinkTitle = (subscription: Subscription): string => {
    if (subscription.payment_method === null) {
      return t('common.copy_payment_method_link');
    }
    return t('common.request_payment_method_change');
  };

  const availableActions: SubscriptionActionDefinition[] = [
    {
      actionType: 'view_details',
      label: t('common.show_details'),
      cb: (subscription: Subscription) => {
        history.push(`/subscriptions/${subscription.id}/detail`);
        trackEvent('view_subscription_details');
      },
    },
    {
      actionType: 'approve',
      label: t('screens.subscriptions.action', {
        action: t('common.approve'),
      }),
      cb: (subscription: Subscription) => {
        trackEvent('approve_subscription');
        actionsModal.open({
          actionType: 'approve',
          subscription: [subscription],
        });
      },
      disabled: (subscription: Subscription) =>
        !canApproveSubscription() || !isApprovable(subscription),
    },
    {
      actionType: 'reject',
      label: t('screens.subscriptions.action', {
        action: t('common.reject'),
      }),
      cb: (subscription: Subscription) => {
        trackEvent('reject_subscription');
        actionsModal.open({
          actionType: 'reject',
          subscription: [subscription],
        });
      },
      disabled: (subscription: Subscription) =>
        !canApproveSubscription() || !isRejectable(subscription),
    },
    {
      actionType: 'cancel',
      label: t('screens.subscriptions.action', {
        action: t('common.cancel'),
      }),
      variant: 'alert',
      cb: (subscription: Subscription) => {
        trackEvent('cancel_subscription');
        actionsModal.open({
          actionType: 'cancel',
          subscription: [subscription],
        });
      },
      disabled: (subscription: Subscription) =>
        !canManageSubscriptions() || isInactionable(subscription),
    },
    {
      actionType: 'activate',
      label: t('screens.subscriptions.action', {
        action: t('common.activate'),
      }),
      cb: (subscription: Subscription) => {
        trackEvent('activate_subscription');
        actionsModal.open({
          actionType: 'activate',
          subscription: [subscription],
        });
      },
      disabled: (subscription: Subscription) =>
        !canManageSubscriptions() ||
        !isActivable(subscription) ||
        isInactionable(subscription),
    },
    {
      actionType: 'pause',
      label: t('screens.subscriptions.action', {
        action: t('common.pause'),
      }),
      cb: (subscription: Subscription) => {
        trackEvent('pause_subscription');
        actionsModal.open({
          actionType: 'pause',
          subscription: [subscription],
        });
      },
      disabled: (subscription: Subscription) =>
        !canManageSubscriptions() ||
        !isPausable(subscription) ||
        isInactionable(subscription),
    },
    {
      actionType: 'change_billing_date',
      label: t('common.change_billing_date'),
      cb: (subscription: Subscription) => {
        trackEvent('change_subscription_billing_date');
        actionsModal.open({
          actionType: 'change_billing_date',
          subscription: [subscription],
        });
      },
      disabled: (subscription: Subscription) =>
        !canManageSubscriptions() || !canChangeBillingDate(subscription),
    },
    {
      actionType: 'ask_payment_method',
      label: t('common.ask_payment_method'),
      cb: (subscription: Subscription) => {
        trackEvent('ask_subscription_payment_method');
        actionsModal.open({
          actionType: 'ask_payment_method',
          subscription: [subscription],
        });
      },
      disabled: (subscription: Subscription) =>
        !canManageSubscriptions() || isInactionable(subscription),
    },
    {
      actionType: 'edit',
      label: t('common.edit_subscription'),
      cb: (subscription: Subscription) => {
        trackEvent('edit_subscription');
        history.push(`/subscriptions/${subscription.id}/edit`);
      },
      disabled: (subscription: Subscription) =>
        !canManageSubscriptions() || isInactionable(subscription),
    },
    {
      actionType: 'copy_checkout_link',
      label: getChangePaymentLinkTitle,
      cb: (subscription: Subscription) => {
        trackEvent('copy_subscription_payment_link');
        clipboard.copy(subscription.checkout_link);
      },
      tooltipLabel: t('common.copied'),
      disabled: (subscription: Subscription) =>
        !subscription.may_change_payment_method || isInactionable(subscription),
    },
    {
      actionType: 'change_payment_method',
      label: (subscription) =>
        subscription.payment_method
          ? t('common.change_payment_method')
          : t('common.add_payment_method'),
      cb: (subscription: Subscription) => {
        trackEvent('change_payment_method');
        paymentMethodModal.open(subscription);
      },
      disabled: (subscription: Subscription) =>
        !subscription.may_change_payment_method || isInactionable(subscription),
    },
  ];

  const actions = omit
    ? availableActions.filter((action) => {
        return !omit.includes(action.actionType);
      })
    : availableActions;

  return { actions, actionsModal, paymentMethodModal };
}
