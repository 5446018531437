import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Button, CellText, H2, useModal } from '@increasecard/typed-components';
import { Warning } from '@styled-icons/material/Warning';
import { Close } from '@styled-icons/material/Close';
import { formatCurrencyType } from '../../utils/currencyUtils';
import { applyExtra, applyExtras, sortExtras } from '../../utils/extrasUtils';
import { ScreenSeparator } from '../../components/common/ScreenSeparator';
import {
  ExtrasPickerButton,
  ExtrasPickerButtonProps,
} from './ExtrasPickerButton';
import { Country, Currency, Extra } from '../../types/common';
import { getCountryConfig } from '../../utils/countryUtils';

const Container = styled.div<{ alignItems?: 'start' | 'end' }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: ${({ alignItems = 'start' }) => alignItems};
  .list {
    max-width: 500px;
    width: 100%;
  }
  .list > * {
    display: grid;
    grid-gap: 0.5rem;
    margin: 0.5rem 0;
  }
  .list .item {
    grid-template-columns: 1fr 1fr 40px;
    grid-auto-rows: auto;
    grid-gap: 0.5rem;
    align-items: center;
    height: 30px;
  }
  .list .add-button,
  .list .separator {
    grid-template-columns: 1fr 40px;
    justify-items: end;
  }
  .list .text-right {
    text-align: right;
  }
  .list .align-left {
    justify-items: start;
  }
`;

export interface ExtrasAggregatorProps {
  items: Extra[];
  onListChange: (list: Extra[]) => void;
  country: Country;
  currency: Currency;
  subtotal: number;
  align?: 'start' | 'end';
  confirmRemoveMessage?: string;
  allowedExtras?: ExtrasPickerButtonProps['allowedExtras'];
}

export function ExtrasAggregator({
  items = [],
  onListChange,
  country,
  currency,
  subtotal,
  align,
  confirmRemoveMessage,
  allowedExtras,
}: ExtrasAggregatorProps): JSX.Element {
  const { t } = useTranslation();
  const confirmRemoveModal = useModal();

  const language = getCountryConfig(country).language;

  const { total, totalBeforeDiscounts, totalBeforeOTCs, totalBeforeTaxes } =
    applyExtras(items, subtotal);

  // If subtotal is not a number do not show substotal nor compute totals.
  const computeValues = typeof subtotal === 'number';

  function addItem(extra: Extra) {
    const alreadyAdded = items.find(({ id }) => id === extra.id);
    if (alreadyAdded) {
      return;
    }
    const newList = [...items, extra].sort(sortExtras);
    onListChange(newList);
  }

  function removeItem(extra: Extra) {
    const newList = items.filter(({ id }) => id !== extra.id);
    onListChange(newList);
  }

  function handleRemoveClick(extra: Extra) {
    // Only discounts and taxes are removed from subscriptions.
    if (confirmRemoveMessage && extra.extraType !== 'one_time_cost') {
      confirmRemoveModal.openModal(null, {
        icon: <Warning size="48px" />,
        colorPalette: 'alert',
        headerText: t('components.extras_aggregator.remove_modal.header', {
          name: extra.name,
        }),
        description: confirmRemoveMessage,
        okLabel: t('components.extras_aggregator.remove_modal.confirm'),
        cancelLabel: t('common.cancel'),
        onOk: () => {
          removeItem(extra);
          confirmRemoveModal.closeCurrentModal();
        },
        okButtonVariant: 'alert',
      });
    } else {
      removeItem(extra);
    }
  }

  const getExtraValue = (extra: Extra) => {
    let base;
    switch (extra.extraType) {
      case 'tax':
        base = totalBeforeTaxes;
        break;
      case 'one_time_cost':
      case 'additional_cost':
        base = totalBeforeOTCs;
        break;
      case 'discount':
        base = totalBeforeDiscounts;
        break;
    }
    return computeValues
      ? formatCurrencyType(
          'flat',
          applyExtra(extra, base),
          extra.currency || currency,
          language
        )
      : formatCurrencyType(
          extra.type,
          Number(extra.amount),
          extra.currency,
          language
        );
  };

  return (
    <>
      <Container alignItems={align}>
        <ExtrasPickerButton
          onSelect={addItem}
          country={country}
          currency={currency}
          allowedExtras={allowedExtras}
        />
        <div className="list">
          {computeValues && (
            <div className="item">
              <CellText
                id="subtotal_to_bill"
                className="extra-name"
                weight="bold"
              >
                {t('common.net_subtotal')}
              </CellText>
              <CellText
                className="text-right"
                aria-labelledby="subtotal_to_bill"
                weight="bold"
              >
                {formatCurrencyType('flat', subtotal, currency, language)}
              </CellText>
            </div>
          )}
          {items.map((extra, i) => (
            <div className="item" key={i}>
              <CellText>
                <strong style={{ marginRight: '2px' }}>
                  {extra.name + ':'}
                </strong>
                {formatCurrencyType(
                  extra.type,
                  Number(extra.amount),
                  extra.currency,
                  language
                )}
              </CellText>
              <CellText weight="bold" className="text-right">
                {getExtraValue(extra)}
              </CellText>
              <Button
                style={{ color: 'black' }}
                onClick={() => handleRemoveClick(extra)}
                buttonType="invisible"
                icon={<Close />}
                size="small"
              />
            </div>
          ))}
          {computeValues && (
            <>
              <ScreenSeparator className="separator" margin="10px 0 0 0" />
              <div className="item with-border">
                <CellText
                  id="total_to_bill"
                  className="extra-name"
                  weight="bold"
                >
                  {t('common.total_to_bill')}
                </CellText>
                <H2
                  className="text-right"
                  aria-labelledby="total_to_bill"
                  style={{
                    textAlign: 'end',
                    whiteSpace: 'nowrap',
                  }}
                  weight="bold"
                >
                  {formatCurrencyType(
                    'flat',
                    Math.max(total, 0),
                    currency,
                    language
                  )}
                </H2>
              </div>
            </>
          )}
        </div>
      </Container>
    </>
  );
}
