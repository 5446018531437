import { ReactNode } from 'react';
import { Help } from '@styled-icons/material/Help';
import { Warning } from '@styled-icons/material/Warning';
import { useModal } from '@increasecard/typed-components';
import { IconButton } from './IconButton';
import { useTranslation } from 'react-i18next';

export interface IconButtonModalProps {
  width?: string;
  height?: string;
  iconType: 'warning' | 'help';
  children?: ReactNode;
  headerText?: ReactNode;
  iconColor?: 'gray' | 'white';
}

export function IconButtonModal({
  width,
  height,
  iconType,
  children,
  headerText,
  iconColor,
}: IconButtonModalProps): JSX.Element {
  const iconButtonModal = useModal();
  const { t } = useTranslation();

  const openModal = () => {
    const icon =
      iconType === 'warning' ? (
        <Warning color="white" size="48px" />
      ) : (
        <Help size="48px" />
      );
    const modalId = iconButtonModal.openModal(children, {
      headerText,
      okLabel: t('common.close'),
      cancelLabel: '',
      onOk: () => iconButtonModal.closeModal(modalId as unknown as number),
      colorPalette: iconType === 'warning' ? 'alert' : 'pay',
      icon,
    });
  };
  return (
    <IconButton
      onClick={openModal}
      width={width}
      height={height}
      iconType={iconType}
      color={iconColor}
    />
  );
}
