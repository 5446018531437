import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Warning } from '@styled-icons/material/Warning';
import { PaymentMethodLabel } from '../../../components/paymentMethods/PaymentMethodLabel';
import { PaymentMethod } from '../../../types/PaymentMethod';
import { Column } from '../../../components/common/Column';
import {
  Paragraph,
  Modal,
  ModalFooter,
  Button,
  ModalHeaderIconBox,
  Spinner,
} from '@increasecard/typed-components';
import { useDeleteCustomerPaymentMethod } from '../customerQueries';

const MODAL_WIDTH = '569';

const noop = () => {
  return;
};
export interface DeletePaymentMethodModalProps {
  visible: boolean;
  customerId: string;
  paymentMethod: PaymentMethod;
  onCancel: () => void;
  onDelete: () => void;
}

export function DeletePaymentMethodModal({
  visible,
  customerId,
  paymentMethod,
  onCancel,
  onDelete,
}: DeletePaymentMethodModalProps): JSX.Element | null {
  const { t } = useTranslation();
  const { isLoading, error, mutate, reset } = useDeleteCustomerPaymentMethod();
  useEffect(() => {
    if (!visible && error) {
      reset();
    }
  }, [error, reset, visible]);

  const handleDeletePaymentMethod = () => {
    mutate(
      { customerId, paymentMethodId: paymentMethod.id },
      {
        onSuccess: onDelete,
      }
    );
  };

  const getContent = () => {
    if (isLoading) {
      return <Spinner size={48} />;
    }
    if (error) {
      return (
        <Paragraph>{`${t('common.error')}: ${t(
          `api.errors.${error.code}`
        )}`}</Paragraph>
      );
    }
    if (paymentMethod) {
      return (
        <>
          <Paragraph>
            {t('screens.customer_form.delete_confirm_message')}
          </Paragraph>
          <PaymentMethodLabel
            paymentMethod={paymentMethod}
            showFullDescription={true}
          />
        </>
      );
    }
  };

  return (
    <Modal
      align="center"
      onClose={onCancel}
      visible={visible}
      width={MODAL_WIDTH}
      onOk={noop}
      onCancel={noop}
    >
      <ModalHeaderIconBox colorPalette="alert" icon={<Warning size="48px" />} />
      <Column alignItems="center" flexGap="0.5rem" textAlign="center">
        {getContent()}
      </Column>
      <ModalFooter align="center">
        <Button onClick={onCancel} buttonType="invisible" disabled={isLoading}>
          {t(error ? 'common.close' : 'common.cancel')}
        </Button>
        {!error && (
          <Button
            onClick={handleDeletePaymentMethod}
            buttonType="alert"
            disabled={isLoading}
          >
            {t('screens.customer_form.yes_delete')}
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
}
