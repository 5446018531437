import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';
import { FullPageSpinner } from '../../components/common/FullPageSpinner';
import { H1, useModal } from '@increasecard/typed-components';
import { ApiErrorMessage } from '../../components/ErrorMessage';
import { filterUnchangedKeys } from '../../utils/apiUtils';
import { CustomerForm, CustomerFormValues } from './CustomerForm';
import { CheckCircle } from '@styled-icons/material/CheckCircle';

import { useCustomerById } from '../../globalQueries/Queries';
import { useUpdateCustomer } from './customerQueries';

export function EditCustomerScreen(): JSX.Element {
  const { t } = useTranslation();
  const successModal = useModal();
  const { customer_id: customerId } = useParams<{ customer_id: string }>();
  const history = useHistory();

  const customer = useCustomerById(customerId);
  const updateCustomer = useUpdateCustomer();

  const gotToCustomersScreen = () => {
    history.replace('/customers');
  };

  const openSuccessModal = () => {
    successModal.openModal(null, {
      icon: <CheckCircle size="48px" />,
      headerText: t(`screens.customer_form.success_edit`),
      okLabel: t('common.accept'),
      cancelLabel: '',
      onOk: gotToCustomersScreen,
      onClose: gotToCustomersScreen,
    });
  };

  const handleSubmit = (customerData: CustomerFormValues) => {
    updateCustomer.mutate(
      {
        id: customerId,
        ...filterUnchangedKeys(customerData, customer.data),
      },
      { onSuccess: () => openSuccessModal() }
    );
  };

  if (customer.error) return <ApiErrorMessage error={customer.error} />;

  if (customer.isLoading || !customer.data) return <FullPageSpinner />;

  return (
    <>
      <H1 style={{ marginBottom: '3rem' }}>
        {t('screens.customer_form.update_title', {
          name: `${customer.data?.first_name} ${customer.data?.last_name}`,
        })}
      </H1>
      <CustomerForm
        initialValue={customer.data}
        submitText={t('common.save')}
        onSubmit={handleSubmit}
        onCancel={gotToCustomersScreen}
        isSubmitting={updateCustomer.isLoading}
        error={updateCustomer.error}
      />
    </>
  );
}
