import { useState } from 'react';
import { Subscription } from '../../types/Subscription';
import { ApiErrorMessage } from '../../components/ErrorMessage';
import { useSubscriptionEvents } from './subscriptionQueries';
import { EventList } from '../../components/listing/EventList';

export interface SubscriptionEventsProps {
  subscription: Subscription;
}

export function SubscriptionEvents({
  subscription,
}: SubscriptionEventsProps): JSX.Element {
  const [pageNumber, setPageNumber] = useState(1);
  const events = useSubscriptionEvents(subscription.id, pageNumber);

  return (
    <>
      <EventList
        events={events.data?.data}
        paginationProps={{
          pages: events.data?.total_pages || 0,
          selected: parseInt(events.data?.current_page || '1'),
          onSelectPage: (page: number) => setPageNumber(page),
        }}
      />
      <ApiErrorMessage error={events.error} />
    </>
  );
}
