/// <reference types="vite-plugin-svgr/client" />
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Grid,
  H2,
  InputLabel,
  CellText,
  Paragraph,
} from '@increasecard/typed-components';
import { Warning } from '@styled-icons/material/Warning';
import styled from 'styled-components';
import BankIcon from '../common/assets/BankIcon.svg?react';
import IncreaseDim from '../common/assets/IncreaseDim.svg?react';
import CardChip from '../common/assets/CardChip.svg?react';
import { cardBanksIcons } from '../../utils/paymentUtils';
import { HelpIcon } from '../common/HelpIcon';
import { NewApiError } from '../../types/NewApiError';
import { ApiErrorMessage } from '../ErrorMessage';

type Overlay = 'error' | 'help';

const StyledBox = styled.div`
  position: relative;
  width: 100%;
  height: 200px;
  padding: 20px;
  background: var(--color-gray-graySLight);
  box-shadow: ${({ theme }) => theme.shadows.elevateCast};
  border-radius: 16px;
`;

const StyledDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledInputLabel = styled(InputLabel)`
  color: var(--color-gray-grayBold);
`;

const StyledWarningIcon = styled(Warning)`
  fill: white;
  margin-bottom: 10px;
`;

const StyledH2 = styled(H2)`
  margin: 40px 0px 10px 0px;
`;

const OverlayWrapper = styled.div<{ type: Overlay }>`
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${({ type }) =>
    type === 'error' ? 'var(--color-coral-regular)' : 'white'};
  opacity: ${({ type }) => (type === 'error' ? 0.95 : 'initial')};
  border-radius: 16px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  padding: 20px 30px;
  z-index: 2;
`;

const Ornament = styled.div<{ type: Overlay }>`
  position: absolute;
  width: 292.39px;
  height: 292.39px;
  left: -122px;
  bottom: -105.66px;
  background: ${({ type }) =>
    type === 'error'
      ? 'var(--color-coral-medium)'
      : 'var(color-gray-grayLight)'};
  border-radius: 58.9334px;
  transform: rotate(45deg);
  z-index: -1;
`;

export interface SupportedCardsOverlayProps {
  supportedCards: React.ComponentType<React.SVGProps<SVGSVGElement>>[] | null;
}

export function SupportedCardsOverlay({
  supportedCards,
}: SupportedCardsOverlayProps): JSX.Element | null {
  const { t } = useTranslation();
  if (!supportedCards) return null;
  return (
    <OverlayWrapper type="help">
      <Ornament type="help" />
      <CellText style={{ marginBottom: '1rem' }}>
        {t('data.payment_method.cards_allowed')}
      </CellText>
      <div>
        {supportedCards.map((Card, i) => (
          <Card key={i} width={35} height={35} style={{ margin: '0px 5px' }} />
        ))}
      </div>
    </OverlayWrapper>
  );
}

interface ErrorOverlayProps {
  errorObj: NewApiError | null;
}

function ErrorOverlay({ errorObj }: ErrorOverlayProps): JSX.Element {
  return (
    <OverlayWrapper type="error" data-ref="payment.error">
      <Ornament type="error" />
      <StyledWarningIcon size="48px" />
      <Paragraph style={{ marginBottom: '1rem', color: 'white' }}>
        <ApiErrorMessage error={errorObj} flatMessage={true} />
      </Paragraph>
    </OverlayWrapper>
  );
}

export type PaymentData = {
  cardNumber: string;
  name: string;
  expirationDate: string;
  cardBrandId: string;
  supportedCards: string;
};

interface InfoBoxProps {
  data: Metadata;
  errorObj?: NewApiError | null;
}

function CardInfoBox({ data, errorObj }: InfoBoxProps): JSX.Element {
  const { t } = useTranslation();
  const [showHelp, setShowHelp] = useState(false);
  const { cardNumber, name, expirationDate, cardBrandId, supportedCards } =
    data;
  const toggleShowHelp = () => setShowHelp(!showHelp);
  const CardIcon =
    (cardBrandId &&
      cardBanksIcons[
        cardBrandId.toLowerCase() as keyof typeof cardBanksIcons
      ]) ||
    IncreaseDim;
  const getNumber = () => {
    const CARD_CHARS = 19;
    const placeholder = '###################';
    const number =
      cardNumber + placeholder.slice(cardNumber.length, CARD_CHARS);
    const addSpacesBetween = (string: string, charsBetween = 4) =>
      string
        .split('')
        .map((elem, i) => ((i + 1) % charsBetween === 0 ? elem + ' ' : elem))
        .join('');
    return addSpacesBetween(number);
  };
  return (
    <StyledBox>
      {supportedCards && (
        <HelpIcon
          position={{ top: '-26px', right: '3px' }}
          onClick={toggleShowHelp}
        />
      )}
      {showHelp && <SupportedCardsOverlay supportedCards={supportedCards} />}
      {errorObj && <ErrorOverlay errorObj={errorObj} />}
      <StyledDiv>
        <CardChip />
        <CardIcon />
      </StyledDiv>
      <StyledH2 aria-label="card">{getNumber()}</StyledH2>
      <StyledDiv>
        <div>
          <StyledInputLabel>
            {t('data.payment_method.cardholder')}
          </StyledInputLabel>
          <CellText>
            {name || t('data.payment_method.cardholder_name_placeholder')}
          </CellText>
        </div>
        <div>
          <StyledInputLabel>
            {t('data.payment_method.expiration_date')}
          </StyledInputLabel>
          <CellText>
            {expirationDate || t('data.payment_method.exp_date_placeholder')}
          </CellText>
        </div>
      </StyledDiv>
    </StyledBox>
  );
}

function CBUInfoBox({ data, errorObj }: InfoBoxProps): JSX.Element {
  const { cbu } = data;
  const getCBU = () => {
    const CBU_CHARS = 22;
    const placeholder = '######################';
    return cbu + placeholder.slice(cbu.length, CBU_CHARS);
  };
  return (
    <StyledBox>
      {errorObj && <ErrorOverlay errorObj={errorObj} />}
      <BankIcon style={{ marginBottom: '20px' }} />
      <H2 aria-label="cbu">{getCBU()}</H2>
    </StyledBox>
  );
}

export interface PaymentMethodInfoBoxProps extends InfoBoxProps {
  type: 'card' | 'cbu';
}

export function PaymentMethodInfoBox({
  type,
  data,
  errorObj,
}: PaymentMethodInfoBoxProps): JSX.Element {
  return (
    <Grid rowGap={2} style={{ width: '323.6px', maxWidth: '323.6px' }}>
      {type === 'cbu' ? <CBUInfoBox data={data} errorObj={errorObj} /> : null}
      {type === 'card' ? <CardInfoBox data={data} errorObj={errorObj} /> : null}
    </Grid>
  );
}
