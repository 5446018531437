import { useTranslation } from 'react-i18next';
import {
  Drawer,
  InputText,
  InputTextProps,
} from '@increasecard/typed-components';
import { PaymentMethodInputDecorator } from './PaymentMethodInputDecorator';
import NumberFormat from 'react-number-format';
import { useModal } from '../../hooks/useModal';
import {
  SupportedCardsOverlay,
  SupportedCardsOverlayProps,
} from './PaymentMethodInfoBox';
import { Constants } from '../../constants';

export interface CreditCardInputProps
  extends SupportedCardsOverlayProps,
    InputTextProps {
  cardBrand: string;
  showMethodIcon: boolean;
  showHelpIcon: boolean;
  onChange: (data: string) => void;
  className: string;
}

export function CreditCardInput({
  cardBrand,
  showMethodIcon,
  showHelpIcon,
  supportedCards,
  onChange,
  className,
  ...inputProps
}: CreditCardInputProps): JSX.Element {
  const { t } = useTranslation();
  const supportedCardsModal = useModal();

  return (
    <>
      <PaymentMethodInputDecorator
        className={className}
        cardBrand={cardBrand}
        type={Constants.PaymentMethods.CARD}
        showMethodIcon={showMethodIcon}
        showHelpIcon={showHelpIcon}
        onHelpIconClick={supportedCardsModal.open}
      >
        <NumberFormat
          customInput={InputText}
          label={t('data.payment_method.card_number')}
          placeholder={t('data.payment_method.card_placeholder')}
          onselectstart="return false"
          onpaste="return false"
          oncopy="return false"
          oncut="return false"
          ondrag="return false"
          ondrop="return false"
          autocomplete="off"
          required
          format="#### #### #### #### ###"
          onValueChange={({ value }) => onChange && onChange(value)}
          {...inputProps}
        />
      </PaymentMethodInputDecorator>
      <Drawer
        visible={supportedCardsModal.isOpen}
        onClose={supportedCardsModal.close}
      >
        <div style={{ height: '200px' }}>
          <SupportedCardsOverlay supportedCards={supportedCards} />
        </div>
      </Drawer>
    </>
  );
}
