import { Build } from '@styled-icons/material/Build';
import { Sell } from '@styled-icons/material-sharp/Sell';
import { DonutLarge } from '@styled-icons/material/DonutLarge';
import { AttachMoney } from '@styled-icons/material/AttachMoney';
import { ExtraType } from '../../types/common';

export interface ExtraModalIconProps {
  extraType: ExtraType | null;
}
export function ExtraModalIcon({
  extraType,
}: ExtraModalIconProps): JSX.Element | null {
  switch (extraType) {
    case 'tax':
      return <DonutLarge size="48px" />;
    case 'discount':
      return <Sell size="48px" />;
    case 'one_time_cost':
      return <Build size="48px" />;
    case 'additional_cost':
      return <AttachMoney size="48px" />;
    default:
      return null;
  }
}
