export const staging = {
  productName: 'pay',
  urls: {
    api: {
      platform: 'https://gateway.staging.increase.app/platform',
      pay: 'https://gateway.staging.increase.app/pay',
    },
    platform: 'https://platform.staging.increase.app',
    auth: 'https://auth.staging.increase.app',
    pay: 'https://pay.staging.increase.app',
    landing: 'https://staging.increase.app',
  },
  collectMethods: {
    mercadopago: {
      ARG: {
        PK: 'TEST-9c5e5037-5cc6-4bc8-825b-2c9670ac69eb',
        integrationUrl:
          'https://auth.mercadopago.com.ar/authorization?client_id=8309189038596948&response_type=code&platform_id=mp&state=collect_method_id={{COLLECT_METHOD_ID}}&redirect_uri=https://gateway.staging.increase.app/pay/integrations/mercadopago/callback',
      },
      PER: {
        PK: 'TEST-f7b0befb-da7d-4328-8ca5-c86c0ce43f0a',
        integrationUrl:
          'https://auth.mercadopago.com.pe/authorization?client_id=7880262259493647&response_type=code&platform_id=mp&state=collect_method_id={{COLLECT_METHOD_ID}}&redirect_uri=https://gateway.staging.increase.app/pay/integrations/mercadopago/callback',
      },
      COL: {
        PK: 'TEST-f5021468-1b24-4575-bcd7-3ca0f846f4c7',
        integrationUrl:
          'https://auth.mercadopago.com.co/authorization?client_id=6290364447784205&response_type=code&platform_id=mp&state=collect_method_id={{COLLECT_METHOD_ID}}&redirect_uri=https://gateway.staging.increase.app/pay/integrations/mercadopago/callback',
      },
      CHL: {
        PK: 'TEST-2b4690f1-cc1a-48d8-b137-d4bf249dbb1a',
        integrationUrl:
          'https://auth.mercadopago.cl/authorization?client_id=6433310211304480&response_type=code&platform_id=mp&state=collect_method_id={{COLLECT_METHOD_ID}}&redirect_uri=https://gateway.staging.increase.app/pay/integrations/mercadopago/callback',
      },
    },
    dlocal: {
      URL: 'https://js-sandbox.dlocal.com',
      PK: 'e9a1daf1-53a5-4650-8442-20a955c8cfd6',
    },
    decidir: {
      sdkUrl: 'https://live.decidir.com/static/v2.5/decidir.js',
      apiUrl: 'https://developers.decidir.com/api/v2',
      PK: 'e9cdb99fff374b5f91da4480c8dca741',
    },
    payments_os: {
      sdkUrl: 'https://js.paymentsos.com/v3/latest/secure-fields.min.js',
      payuSecurity:
        'https://maf.pagosonline.net/ws/fp/tags.js?id={{deviceSessionId}}80200',
    },
  },
  eventTracking: {
    enabled: false,
    mixpanelToken: '6a0697c73a29ddfc6736a34574440b9f',
    debug: false,
  },
  errorTracking: {
    enabled: false,
    rollbarToken: '33f4dd86315842fda658d7a419a6af07',
  },
  useAuthorizations: false,
  reCaptchaSiteKey: '6LfRL1opAAAAACqwk3SXQ4mP5AC8IdIt-DF3L-XS',
  reCaptchaFakeToken:
    '88e87797ecc0433b2a638d2d3c33d0490638f100b946592394bc4a1ce4331dcd',
};
