export const Constants = {
  TINY_LIST_PAGE_SIZE: 5,
  SMALL_LIST_PAGE_SIZE: 6,
  MEDIUM_PAGE_SIZE: 10,
  LARGE_PAGE_SIZE: 12,
  MAX_PAGE_SIZE: 25,
  TYPING_DEBOUNCE_MS: 500,
  CELL_TEXT_MAX_SIZE_CHARS: 40,
  SELECT_TEXT_MAX_SIZE_CHARS: 23,
  PaymentMethods: {
    CBU: 'cbu',
    CARD: 'card',
    TICKET: 'ticket',
  },
  CollectMethods: {
    CBU: {
      COMAFI: 'comafi',
      SUPERVIELLE: 'supervielle',
    },
    CARD: {
      MERCADOPAGO_GATEWAY: 'mercadopago', // Deprecated
      MERCADOPAGO_AGGREGATOR: 'mercadopago_connect',
      DLOCAL: 'dlocal',
      DLOCALCROSS: 'dlocal_cross',
      DECIDIR: 'decidir',
      PAYMENTS_OS: 'payments_os',
    },
  },
  PaymentMethodContext: {
    CUSTOMER: 'customer',
    INVOICE: 'invoice',
    SUBSCRIPTION: 'subscription',
  },
};
