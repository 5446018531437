/* eslint-disable react/prop-types */
import styled from 'styled-components';
import { typography } from '@increasecard/typed-components';
import { Box } from '../common/Box';
import { LabelError } from '../common/LabelError';

interface InputWrapperProps {
  internalClassPrefix?: string;
}

const InputWrapper = styled.div<InputWrapperProps>`
  &.${({ internalClassPrefix }) => internalClassPrefix},
    .${({ internalClassPrefix }) => internalClassPrefix},
    &.${({ internalClassPrefix }) => internalClassPrefix}--empty,
    .${({ internalClassPrefix }) => internalClassPrefix}--empty {
    ${typography('cell')}
    appearance: none;
    background: var(--color-gray-white);
    border: 1px solid var(--color-gray-grayMedium);
    border-radius: 4px;
    outline: none;
    margin: 0;
    padding: 11px;

    width: 100%;
    resize: none;
    text-align: inherit;
    transition:
      border 200ms,
      box-shadow 200ms;

    :hover {
      border: 1px solid var(--color-increase-light50);
    }
  }

  &.${({ internalClassPrefix }) => internalClassPrefix}--invalid,
    .${({ internalClassPrefix }) => internalClassPrefix}--invalid {
    border: 1px solid var(--color-coralAlert-regular);
  }

  &.${({ internalClassPrefix }) => internalClassPrefix}--focus,
    .${({ internalClassPrefix }) => internalClassPrefix}--focus {
    border: 1px solid var(--color-increase-light50);
    box-shadow: 0 0 0 4px var(--color-increase-light);
    outline: 0;
  }
`;

interface LabelProps {
  hasError: boolean;
}

const Label = styled.label<LabelProps>`
  ${typography('inputLabel')};
  display: inline-flex;
  font-weight: 500;
  white-space: nowrap;
  margin-bottom: 6px;
  ${({ hasError }) => hasError && `color: var(--color-coralAlert-dark);`}
  &::after {
    content: '*';
    margin-left: 1px;
    color: var(--color-increase-regular);
  }
`;

export interface FieldInputWrapperProps extends InputWrapperProps {
  id: string;
  label: string;
  errorMessage?: string | null;
  className?: string;
}

export function FieldInputWrapper({
  id,
  label,
  errorMessage,
  className,
  internalClassPrefix = 'field',
}: FieldInputWrapperProps): JSX.Element {
  return (
    <Box display="inline-flex" flexDirection="column" className={className}>
      <Label htmlFor={id} hasError={!!errorMessage}>
        {label}
      </Label>
      <InputWrapper id={id} internalClassPrefix={internalClassPrefix} />
      {errorMessage && <LabelError>{errorMessage}</LabelError>}
    </Box>
  );
}
