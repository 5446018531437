import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';
import { Button, Grid, H1, H2, Spinner } from '@increasecard/typed-components';
import { GoBackButton } from '../../components/common/GoBackButton';
import { getCustomerName } from '../../utils/utils';
import { SubscriptionDetails } from './SubscriptionDetails';
import { SubscriptionEvents } from './SubscriptionEvents';
import { RoundedCard } from '../../components/common/RoundedCard';
import { formatISODate } from '../../utils/dateUtils';
import { SubscriptionTags } from './components/SubscriptionTags';
import { isInactionable } from '../../utils/subscriptionUtils';
import { useSubscriptionById } from './subscriptionQueries';
import { Row } from '../../components/common/Row';
import { RouterLink } from '../../components/common/RouterLink';
import { ModeEdit } from '@styled-icons/material/ModeEdit';
import { MergedExtrasList } from '../extras/MergedExtrasList';
import { SubscriptionActionsMenu } from './components/SubscriptionActionsMenu';
import { InvoiceDetail } from '../../components/InvoiceDetail';
import { formatCurrency } from '../../utils/currencyUtils';
import { InvoiceTable } from '../invoice/InvoiceTable';
import { Constants } from '../../constants';
import { useUIEventTracker } from '../../hooks/useUIEventTracker';
import { useScrollToTopOnMount } from '../../hooks/useScrollToTopOnMount';
import { DesynchedAmount } from './components/DesynchedAmount';
import { PaymentMethodDetailBox } from '../../components/paymentMethods/PaymentMethodDetailBox';
import { Column } from '../../components/common/Column';
import { DetailHeader } from '../../components/common/DetailHeader';
import { useModal } from '../../hooks/useModal';
import { AddPMToSubscriptionModal } from '../../components/paymentMethods/AddPMToSubscription';

export function SubscriptionDetailScreen(): JSX.Element {
  useScrollToTopOnMount();
  const { t } = useTranslation();
  const history = useHistory();
  const paymentMethodModal = useModal();
  const { subscription_id } = useParams<{ subscription_id: string }>();
  const { isLoading, data: detail } = useSubscriptionById(subscription_id);
  const trackEvent = useUIEventTracker('detail button');

  if (isLoading || !detail) {
    return <Spinner size={16} />;
  }

  // HOTFIX: hiding next invoice detail if it comes empty.
  // It's coming empty on some scheduled subscriptions.
  const isNextInvoiceEmpty =
    !detail.next_invoice || Object.keys(detail.next_invoice).length === 0;

  const trackEditEvent = () => {
    trackEvent('edit_subscription');
  };

  const debtInvoiceButton = (
    <Button
      buttonType="invisible"
      disabled={!detail.may_create_debt_invoice}
      onClick={() =>
        history.push(`/subscriptions/${subscription_id}/new-debt-invoice`)
      }
    >
      {t('common.manage_debt')}
    </Button>
  );

  return (
    <Grid rowGap={3}>
      <Row margin="0" justifyContent="space-between">
        <GoBackButton />
        <Row margin="0" gap="1rem" width="auto">
          <RouterLink
            to={
              !isInactionable(detail)
                ? `/subscriptions/${subscription_id}/edit`
                : undefined
            }
            icon={<ModeEdit />}
            onClick={trackEditEvent}
            disabled={isInactionable(detail)}
          >
            {t('common.edit')}
          </RouterLink>
          <SubscriptionActionsMenu
            label="Acciones"
            selection={[detail]}
            context="detail actions menu"
            disabled={isInactionable(detail)}
          />
        </Row>
      </Row>
      <DetailHeader
        title={`${t('common.subscription')}: ${detail.product.name} • ${detail.plan.name}`}
        subTitle={`${getCustomerName(detail.customer)} • ${detail.customer.email}`}
        copyValue={detail.customer.email}
        subtitleHref={`/customers/${detail.customer.id}/detail`}
      />
      <Column
        margin="0"
        gap="1rem"
        className="grid-span-desktop-5 grid-span-mobile-12"
        childrenFlex="1"
      >
        <RoundedCard
          className="grid-span-desktop-5 grid-span-mobile-12"
          variant="green"
          centered
          gap="1rem"
        >
          <H2 weight="400">
            {isInactionable(detail)
              ? `Ultimo monto facturado`
              : `Monto a facturar el ${formatISODate(detail.next_billing_date)}`}
          </H2>
          <div>
            <H1>
              {formatCurrency(
                Number(detail.net_amount) > 0 ? detail.net_amount : 0,
                detail.currency
              )}
            </H1>
            <DesynchedAmount subscription={detail} />
          </div>
        </RoundedCard>
        <PaymentMethodDetailBox
          paymentMethod={detail.payment_method}
          onChangeClick={() => paymentMethodModal.open(detail)}
          buttonDisabled={!detail.may_change_payment_method}
        />
      </Column>
      <SubscriptionDetails
        subscription={detail}
        className="grid-span-desktop-7 grid-span-mobile-2"
      />
      {Number(detail.amount_owed) > 0 && (
        <RoundedCard centered variant="alert">
          <H2 weight="400">Saldo deudor</H2>
          <H1>{formatCurrency(detail.amount_owed, detail.currency)}</H1>
          {debtInvoiceButton}
        </RoundedCard>
      )}
      {!isNextInvoiceEmpty && (
        <InvoiceDetail
          invoice={detail.next_invoice}
          title={t('common.next_invoice')}
          subtitle={t('screens.subscriptions.next_invoice_subtitle', detail)}
          productName={detail.product.name}
        />
      )}

      <MergedExtrasList
        entityWithExtras={detail}
        cyclesPast={detail.invoices_generated_count}
      />
      <SubscriptionTags tags={detail.tags} />
      <InvoiceTable
        filters={{
          subscription_id: detail.id,
          per_page: Constants.SMALL_LIST_PAGE_SIZE,
        }}
        header={t('screens.subscriptions.invoices_list_title', {
          invoices_paid_count: detail.invoices_paid_count,
          invoices_generated_count: detail.invoices_generated_count,
        })}
        tableHeaderButtons={debtInvoiceButton}
        context="subscription detail"
      />
      <SubscriptionEvents subscription={detail} />
      <AddPMToSubscriptionModal
        visible={paymentMethodModal.isOpen}
        subscription={paymentMethodModal.data}
        onClose={paymentMethodModal.close}
      />
    </Grid>
  );
}
