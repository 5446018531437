import { Button, ButtonProps } from '@increasecard/typed-components';
import { DeleteForever } from '@styled-icons/material/DeleteForever';

export type DeleteButtonProps = Omit<ButtonProps, 'icon' | 'children'>;

export function DeleteButton(props: DeleteButtonProps): JSX.Element {
  return (
    <Button
      icon={<DeleteForever />}
      size="small"
      variant="alertInvisible"
      {...props}
      type="button"
    />
  );
}
