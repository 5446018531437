import { MouseEvent, ReactNode } from 'react';
import { useHistory } from 'react-router-dom';
import { ChevronLeft } from '@styled-icons/material-outlined/ChevronLeft';
import { RouterLink } from './RouterLink';

export interface GoBackButtonProps {
  children?: ReactNode;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  hideIcon?: boolean;
}

export function GoBackButton({
  children = 'Volver',
  onClick,
  hideIcon,
}: GoBackButtonProps): JSX.Element {
  const history = useHistory();
  return (
    <RouterLink
      icon={hideIcon ? undefined : <ChevronLeft />}
      onClick={onClick || history.goBack}
    >
      {children}
    </RouterLink>
  );
}
