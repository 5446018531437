import styled, { css } from 'styled-components';

export const Tab = styled.button.attrs({ type: 'button' })<{
  active?: boolean;
}>`
  box-sizing: border-box;
  color: var(--color-pay-regular);
  border-bottom: 6px solid transparent;
  border: none;
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  height: 100%;
  text-transform: uppercase;
  margin-bottom: 6px;
  ${({ active }) =>
    active
      ? css`
          border-bottom: 6px solid var(--color-pay-regular);
          margin-bottom: 0px;
        `
      : ''}
  &:hover {
    border-bottom: 6px solid var(--color-pay-regular);
    margin-bottom: 0px;
  }
`;
