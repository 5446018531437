import {
  Modal,
  ModalHeader,
  ModalContent,
  ModalFooter,
  Button,
  Spinner,
  ModalHeaderIconBox,
  Paragraph,
} from '@increasecard/typed-components';
import { useTranslation } from 'react-i18next';
import { Row } from '../../../components/common/Row';
import { NewApiError } from '../../../types/NewApiError';
import { Invoice } from '../../../types/Invoice';
import { ApiErrorMessage } from '../../../components/ErrorMessage';
import { SuccessProcessScreen } from './InvoiceSuccessScreen';
import { CheckCircle } from '@styled-icons/material/CheckCircle';
import { Settings } from '@styled-icons/material/Settings';
import { Cancel } from '@styled-icons/material/Cancel';

const INVOICE_PROCESS_MODAL_WIDTH = '569';

export interface InvoiceProcessModalProps {
  visible: boolean;
  onClose?: () => void;
  onOk: () => void;
  invoice: Invoice | undefined;
  isProcessing: boolean;
  error: NewApiError | null;
}

export function InvoiceProcessModal({
  visible,
  onClose,
  onOk,
  invoice,
  isProcessing,
  error,
}: InvoiceProcessModalProps): JSX.Element | null {
  const { t } = useTranslation();

  const getStatusScreen = () => {
    if (error) {
      return (
        <>
          <ApiErrorMessage error={error} />
          {error.metadata?.rejection_details && (
            <Paragraph>
              {t('common.reason', {
                reason: t(
                  `api.errors.${error.metadata.rejection_details.rejection_code}`
                ),
              })}
            </Paragraph>
          )}
        </>
      );
    }
    if (invoice) {
      return <SuccessProcessScreen invoice={invoice} />;
    }
  };

  const getTitle = () => {
    const translateKey =
      (isProcessing && 'processing') ||
      (error && 'error') ||
      (invoice && 'success');
    return t(`screens.new_invoice.${translateKey}`);
  };

  const headerIcon = isProcessing ? (
    <Settings size="48px" />
  ) : error ? (
    <Cancel size="48px" />
  ) : (
    <CheckCircle size="48px" />
  );
  const colorPalette = error ? 'alert' : 'pay';

  return (
    <Modal
      align="center"
      onClose={() => null}
      visible={visible}
      width={INVOICE_PROCESS_MODAL_WIDTH}
      onOk={() => null}
      onCancel={() => null}
      hideCloseButton
    >
      <ModalHeaderIconBox colorPalette={colorPalette} icon={headerIcon} />
      <ModalHeader>{getTitle()}</ModalHeader>
      <ModalContent>
        {isProcessing ? (
          <Row justifyContent="center">
            <Spinner size={24} />
          </Row>
        ) : (
          getStatusScreen()
        )}
      </ModalContent>
      <ModalFooter align="center">
        <Button onClick={onOk} buttonType="primary">
          {t('common.goto_list')}
        </Button>
        {onClose && (
          <Button onClick={onClose} buttonType="primary">
            {t('common.close')}
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
}
