import { ReactNode } from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { useUser, useAuth, AuthStates } from '@increasecard/increase-core';
import { SingleLayout } from './layout/Layout';

export interface PrivateRouteProps extends RouteProps {
  children: ReactNode;
  layoutHidden?: boolean;
}

export function PrivateRoute({
  children,
  layoutHidden = false,
  ...rest
}: PrivateRouteProps): JSX.Element | null {
  const authState = useAuth({ requireAuth: true });
  const user = useUser();

  if (authState.state !== AuthStates.Authenticated || !user) return null;

  return (
    <Route
      {...rest}
      render={() => {
        return layoutHidden ? (
          children
        ) : (
          <SingleLayout>{children}</SingleLayout>
        );
      }}
    />
  );
}
