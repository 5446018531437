import { useTranslation } from 'react-i18next';
import { CreditCard } from '@styled-icons/material/CreditCard';
import {
  Button,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalHeaderIconBox,
  Spinner,
} from '@increasecard/typed-components';
import { useState } from 'react';
import { PathSwitcher } from '../common/PathSwitcher';
import { CustomerPaymentMethods } from './CustomerPaymentMethods';
import { PaymentMethodCreator } from './PaymentMethodCreator';
import { PaymentMethod, PaymentReference, Subscription } from '../../types';
import { ApiErrorMessage } from '../ErrorMessage';
import {
  useSubscriptionById,
  useUpdateSubscription,
} from '../../features/subscription/subscriptionQueries';

export interface AddPMToSubscriptionModalProps {
  visible: boolean;
  onClose: VoidFunction;
  onExited?: VoidFunction;
  onSucces?: (paymentMethod: PaymentMethod) => void;
  subscription: Subscription | null;
}
// For now, this modal can only operate over an subscription.
export function AddPMToSubscriptionModal({
  visible,
  subscription,
  onClose,
}: AddPMToSubscriptionModalProps): JSX.Element | null {
  const { t } = useTranslation();
  const [createPM, setCreatePM] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const {
    data: fullSusbcription,
    isLoading,
    isPlaceholderData,
  } = useSubscriptionById(subscription?.id || '');

  if (!subscription) return null;

  const isPMChange = !!subscription.payment_method;
  const label = isPMChange
    ? t('common.change_payment_method')
    : t('common.add_payment_method');

  const paymentReference: PaymentReference = {
    reference_type: 'subscription',
    reference_id: subscription.id,
  };

  const handleClose = () => {
    setCreatePM(false);
    setIsSuccess(false);
    onClose();
  };

  const getContent = () => {
    if (isPlaceholderData || !fullSusbcription || isLoading) {
      return <Spinner size={24} />;
    }
    if (isSuccess) {
      return (
        <>
          El método de pago se cambió con éxito
          <ModalFooter align="center">
            <Button onClick={handleClose} buttonType="primary">
              {t('common.close')}
            </Button>
          </ModalFooter>
        </>
      );
    }

    return (
      <>
        Elija o cree un metodo de pago para cobrar la suscripción
        <PathSwitcher
          optionATitle="Elegir un medio de pago existente"
          optionBTitle="Crear nuevo medio de pago"
          value={createPM}
          onChange={setCreatePM}
          style={{ margin: '16px 0' }}
        />
        {createPM ? (
          <PaymentMethodCreator
            onSuccess={() => setIsSuccess(true)}
            customerId={fullSusbcription.customer.id}
            groupedCollectMethods={fullSusbcription.plan.collect_methods}
            paymentReference={paymentReference}
          />
        ) : (
          <UpdatePaymentMethod
            subscription={fullSusbcription}
            onClose={handleClose}
            buttonLabel={label}
            onSuccess={() => setIsSuccess(true)}
          />
        )}
      </>
    );
  };

  return (
    <Modal
      align="center"
      onClose={handleClose}
      visible={visible}
      width="800"
      onOk={() => null}
      onCancel={() => null}
    >
      <ModalHeaderIconBox
        colorPalette="pay"
        icon={<CreditCard size="48px" />}
      />
      <ModalHeader align="center">{label}</ModalHeader>
      <ModalContent align="center">{getContent()}</ModalContent>
    </Modal>
  );
}

interface UpdatePaymentMethodProps {
  subscription: Subscription;
  onClose: VoidFunction;
  buttonLabel: string;
  onSuccess: VoidFunction;
}

function UpdatePaymentMethod({
  subscription,
  onClose,
  buttonLabel,
  onSuccess,
}: UpdatePaymentMethodProps) {
  const { t } = useTranslation();
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<string>();
  const { mutate, isSuccess, isError, isLoading, error } =
    useUpdateSubscription();

  const handlePMSelection = (pm: PaymentMethod) => {
    setSelectedPaymentMethod(pm.id);
  };

  const handleExistingPM = () => {
    if (selectedPaymentMethod) {
      mutate(
        {
          id: subscription.id,
          payment_method_id: selectedPaymentMethod,
        },
        {
          onSuccess,
        }
      );
    }
  };
  const getContent = () => {
    if (isSuccess) {
      return 'El método de pago de la factura se actualizó con éxito.';
    } else {
      return (
        <CustomerPaymentMethods
          customerId={subscription.customer.id}
          onChange={handlePMSelection}
          selectedPaymentMethodId={
            selectedPaymentMethod || subscription.payment_method?.id
          }
          groupedCollectMethods={subscription.plan.collect_methods}
          noPaymentMethodsMsg="El cliente no posee métodos de pago validos para esta suscripción"
        />
      );
    }
  };

  return (
    <>
      {getContent()}
      <ApiErrorMessage error={error} />
      <ModalFooter align="center">
        {isError || isSuccess ? (
          <Button onClick={onClose} buttonType="primary">
            {t('common.close')}
          </Button>
        ) : (
          <>
            <Button
              data-testid="cancel-button"
              onClick={onClose}
              buttonType="invisible"
              disabled={isLoading}
            >
              {t('common.cancel')}
            </Button>
            <Button
              onClick={handleExistingPM}
              disabled={!selectedPaymentMethod}
              isLoading={isLoading}
            >
              {buttonLabel}
            </Button>
          </>
        )}
      </ModalFooter>
    </>
  );
}
