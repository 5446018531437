import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Constants } from '../../constants';
import { ActionsModal } from './components/ActionsModal';
import { formatISODate } from '../../utils/dateUtils';
import { Subscription } from '../../types/Subscription';
import { IconButtonTooltip } from '../../components/common/HelpTooltip';
import {
  ColumnDefinition,
  DataTable,
  RowDefinition,
} from '../../components/common/DataTable/DataTable';
import { ApiErrorMessage } from '../../components/ErrorMessage';
import { useSubscriptions } from './subscriptionQueries';
import { compareAsc, parseISO } from 'date-fns';
import { SubscriptionActionsMenu } from './components/SubscriptionActionsMenu';
import { useSubscriptionActions } from './components/useSubscriptionActions';
import { useSubscriptionFilters } from './components/useSubscriptionFilters';
import { SubscriptionFilters } from './components/SubscriptionFilters';
import { useUIEventTracker } from '../../hooks/useUIEventTracker';
import { nativeUniqBy } from '../../utils/utils';
import { AddPMToSubscriptionModal } from '../../components/paymentMethods/AddPMToSubscription';

interface SubscriptionsListProps {
  customer_id?: string;
}

export function SubscriptionsList({
  customer_id,
}: SubscriptionsListProps): JSX.Element {
  const { t } = useTranslation();
  const history = useHistory();
  const [selectedSubscriptions, setSelected] = useState<Subscription[]>([]);
  const [page, setPage] = useState(1);
  const trackEvent = useUIEventTracker('list row click');

  // state
  const [filters] = useSubscriptionFilters();
  const subscriptions = useSubscriptions(
    page,
    customer_id ? { customers: [customer_id] } : { ...filters }
  );

  const loadPage = setPage;

  const { actions, actionsModal, paymentMethodModal } =
    useSubscriptionActions('list row menu');

  // error
  if (subscriptions.isError) {
    return (
      <ApiErrorMessage
        error={subscriptions.error}
        onClick={() => subscriptions.refetch()}
      />
    );
  }

  const goToDetails = (subscription: Subscription) => {
    trackEvent('view_subscription_details');
    history.push(`/subscriptions/${subscription.id}/detail`);
  };

  const handleSelect = (rows: RowDefinition[], checked: boolean) => {
    if (checked) {
      setSelected(
        nativeUniqBy([...selectedSubscriptions, ...rows], ({ id }) => id)
      );
    } else {
      const newCoso = selectedSubscriptions.filter(
        (rs: RowDefinition) => !rows.find((r) => r.id === rs.id)
      );
      setSelected(newCoso);
    }
  };

  const tableColumns: ColumnDefinition[] = [
    {
      field: 'customer',
      type: 'customer',
      headerName: t('common.customer'),
    },
    {
      field: 'product.name',
      type: 'string',
      headerName: t('common.product'),
    },
    {
      field: 'plan.name',
      type: 'string',
      headerName: t('common.plan'),
    },
    {
      field: 'status',
      type: 'tag',
      headerName: t('common.status'),
      tagType: 'subscription',
      tagLabel: (subscription: RowDefinition) =>
        t(`data.subscription.status.${subscription.status}`),
    },
    {
      field: 'payment_method',
      type: 'payment_method',
      showValidationStatus: true,
      headerName: t('common.payment_method'),
    },
    {
      field: 'next_billing_date',
      type: 'string',
      headerName: t('common.next_bill'),
      // eslint-disable-next-line react/display-name
      valueGetter: (subscription: RowDefinition) => {
        const tooltip = subscription.payment_method &&
          subscription.payment_method.data.type === 'cbu' && (
            <IconButtonTooltip content={t('common.bill_period')} />
          );
        const today = new Date();
        let payDate = '-';
        if (subscription.next_billing_date) {
          payDate =
            compareAsc(today, parseISO(subscription.next_billing_date)) >= 0
              ? formatISODate(today.toISOString())
              : formatISODate(subscription.next_billing_date);
        }
        return (
          <>
            {payDate}
            {tooltip}
          </>
        );
      },
    },
    {
      field: 'net_amount',
      type: 'currency',
      headerName: t('common.amount_to_bill'),
      rightAligned: true,
      disallowNegative: true,
    },
  ];

  if (customer_id) {
    tableColumns.shift();
  }

  const hasData = subscriptions.data?.data
    ? subscriptions.data?.data.length > 0
    : false;

  const getTableHeader = () => {
    if (customer_id) {
      return t('pay_sections.view_subscriptions');
    }
    let header = `Resultados: ${
      subscriptions.isFetching ? 'cargando' : subscriptions.data?.total_elements
    }`;
    if (selectedSubscriptions.length > 0) {
      header += ` (${t('common.selected', {
        count: selectedSubscriptions.length,
      })})`;
    }
    return header;
  };

  return (
    <>
      {!customer_id && <SubscriptionFilters filters={filters} />}
      <DataTable
        tableProps={{ hasData, zebra: true }}
        columns={tableColumns}
        pageSize={Constants.LARGE_PAGE_SIZE}
        isLoading={!hasData && subscriptions.isFetching}
        rows={subscriptions.data?.data || null}
        menuActions={actions}
        hideDisabledOptions={true}
        rowHeight="double"
        paginatorProps={{
          pages: subscriptions.data?.total_pages,
          selected: Number(subscriptions.data?.current_page),
          onSelectPage: loadPage,
        }}
        onRowClick={goToDetails}
        onSelect={handleSelect}
        selectedRows={selectedSubscriptions}
        tableHeader={getTableHeader()}
        tableHeaderButtons={
          <SubscriptionActionsMenu
            onCleanUp={() => setSelected([])}
            label={t('common.massive_actions')}
            selection={selectedSubscriptions}
            context="list header actions menu"
          />
        }
      />
      {/* This modal is for the contextual menu actions.
       * Eventually, a contextual menu component with the modals included could be created
       */}
      <ActionsModal
        onClose={() => actionsModal.close()}
        onExited={() => actionsModal.setData(null)}
        visible={actionsModal.isOpen}
        subscription={actionsModal.data?.subscription}
        actionType={actionsModal.data?.actionType}
      />
      <AddPMToSubscriptionModal
        visible={paymentMethodModal.isOpen}
        onClose={paymentMethodModal.close}
        subscription={paymentMethodModal.data}
      />
    </>
  );
}
