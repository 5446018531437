export const sandbox = {
  productName: 'pay',
  urls: {
    api: {
      platform: 'https://gateway.increase.app/platform',
      pay: 'https://gateway.increase.app/pay-sandbox',
    },
    platform: 'https://platform.increase.app',
    auth: 'https://auth.increase.app',
    pay: 'https://pay-sandbox.increase.app',
    landing: 'https://increase.app',
  },
  collectMethods: {
    mercadopago: {
      ARG: {
        PK: 'APP_USR-e200538b-86c7-4ab0-979f-22c244e7ed41',
        integrationUrl:
          'https://auth.mercadopago.com.ar/authorization?client_id=7832244048377714&response_type=code&platform_id=mp&state=collect_method_id={{COLLECT_METHOD_ID}}&redirect_uri=https://gateway.increase.app/pay-sandbox/integrations/mercadopago/callback',
      },
      PER: {
        PK: 'APP_USR-305fda6d-ff32-45b9-b0a5-92bd95079bb5',
        integrationUrl:
          'https://auth.mercadopago.com.pe/authorization?client_id=7880262259493647&response_type=code&platform_id=mp&state=collect_method_id={{COLLECT_METHOD_ID}}&redirect_uri=https://gateway.increase.app/pay-sandbox/integrations/mercadopago/callback',
      },
      COL: {
        PK: 'APP_USR-6520fe6d-78bb-4fac-9c94-38cd374a90cf',
        integrationUrl:
          'https://auth.mercadopago.com.co/authorization?client_id=6290364447784205&response_type=code&platform_id=mp&state=collect_method_id={{COLLECT_METHOD_ID}}&redirect_uri=https://gateway.increase.app/pay-sandbox/integrations/mercadopago/callback',
      },
      CHL: {
        PK: 'APP_USR-59c1097b-9312-4739-ba83-0757825c3c9c',
        integrationUrl:
          'https://auth.mercadopago.cl/authorization?client_id=6433310211304480&response_type=code&platform_id=mp&state=collect_method_id={{COLLECT_METHOD_ID}}&redirect_uri=https://gateway.increase.app/pay-sandbox/integrations/mercadopago/callback',
      },
    },
    dlocal: {
      URL: 'https://js-sandbox.dlocal.com',
      PK: 'e9a1daf1-53a5-4650-8442-20a955c8cfd6',
    },
    decidir: {
      sdkUrl: 'https://live.decidir.com/static/v2.5/decidir.js',
      apiUrl: 'https://developers.decidir.com/api/v2',
      PK: 'e9cdb99fff374b5f91da4480c8dca741',
    },
    payments_os: {
      sdkUrl: 'https://js.paymentsos.com/v3/latest/secure-fields.min.js',
      payuSecurity:
        'https://maf.pagosonline.net/ws/fp/tags.js?id={{deviceSessionId}}80200',
    },
  },
  eventTracking: {
    enabled: false,
    mixpanelToken: 'e177dab63126395e646fc9ab7d9fb1d8',
    debug: false,
  },
  errorTracking: {
    enabled: true,
    rollbarToken: '33f4dd86315842fda658d7a419a6af07',
  },
  useAuthorizations: true,
  reCaptchaSiteKey: '6LfRL1opAAAAACqwk3SXQ4mP5AC8IdIt-DF3L-XS',
};
