import { Fragment, ReactNode } from 'react';
import styled from 'styled-components';
import { FieldArray, useField } from 'formik';
import { useTranslation } from 'react-i18next';
import { H3, Button, CellText } from '@increasecard/typed-components';
import { formatCurrencyType } from '../../utils/currencyUtils';
import {
  getExtraCurrency,
  getExtraLanguage,
  isAdditionalCost,
  isCoupon,
  isDiscount,
  isExtraActive,
} from '../../utils/extrasUtils';
import { Extra } from '../../types/common';
import { Close } from '@styled-icons/material/Close';
import { InputNumberField } from '../../components/common/FormikFields';

// Yet another IconButton :(
const IconButton = styled(Button)`
  svg {
    fill: var(--color-gray-black);
    margin: 0 3px;
  }
`;

export interface SubscriptionItemsProps {
  name: string;
  editable: boolean; // If allow modify the items or not
  isEditing: boolean; // If is creating or editing the subscription
  title: ReactNode;
  invoicesGeneratedCount: number | undefined;
}

export function SubscriptionItems({
  name,
  editable,
  isEditing,
  title,
  invoicesGeneratedCount,
}: SubscriptionItemsProps): JSX.Element {
  const [, meta] = useField(name);
  const { t } = useTranslation();

  const renderDiscountQuantity = (
    extra: Extra,
    editable: boolean,
    fieldName: string
  ) => {
    if (isDiscount(extra) || isAdditionalCost(extra)) {
      if (extra.duration === 'periodic') {
        return (
          <div className="span-desktop-2 grid-align-center">
            {editable ? (
              // This shoould be binded to cycles_left field with the initial value being cycle_amount
              <InputNumberField
                id={fieldName}
                label={
                  isEditing ? t('common.cycles_left') : t('common.periods')
                }
                type="number"
                min={0}
                step={1}
                size="small"
              />
            ) : (
              <>
                <CellText>
                  {isEditing ? t('common.cycles_left') : t('common.periods')}
                </CellText>
                <CellText id="quantity" weight="bold">
                  {extra.cycle_amounts_left} / {extra.cycle_amount}
                </CellText>
              </>
            )}
          </div>
        );
      } else {
        return (
          <div className="span-desktop-2 grid-align-center">
            <CellText>{t(`common.periods`)}</CellText>
            <CellText weight="bold">
              {t('data.extras.durations.forever')}
            </CellText>
          </div>
        );
      }
    }
    return null;
  };

  return (
    <>
      {meta.value?.length > 0 && <H3>{title}</H3>}
      <FieldArray name={name}>
        {({ remove }) => {
          return (
            meta.value?.map((item: Extra, index: number) => {
              return (
                <Fragment key={item.id}>
                  <CellText className="grid-start-desktop-1 span-desktop-1 grid-align-center"></CellText>
                  <CellText className="span-desktop-2 grid-align-center">
                    {isCoupon(item) ? 'Cupón' : t(`common.${item.extraType}`)}
                  </CellText>
                  <CellText
                    className="span-desktop-3 grid-align-center"
                    weight="bold"
                  >
                    {item.name}
                  </CellText>
                  {renderDiscountQuantity(
                    item,
                    editable,
                    `${name}[${index}].cycle_amount`
                  )}
                  <CellText
                    className="span-desktop-1 grid-start-desktop-11 grid-justify-end grid-align-center"
                    style={{
                      textDecoration: isExtraActive(
                        item,
                        invoicesGeneratedCount
                      )
                        ? 'none'
                        : 'line-through',
                    }}
                  >
                    {formatCurrencyType(
                      item.type,
                      Number(item.amount),
                      getExtraCurrency(item),
                      getExtraLanguage(item)
                    )}
                  </CellText>
                  {editable && (
                    <IconButton
                      title="Eliminar adicional"
                      icon={<Close />}
                      onClick={() => remove(index)}
                      variant="invisible"
                      type="button"
                      data-testid={item.id}
                      className="span-desktop-1 grid-start-desktop-12"
                      style={{
                        position: 'relative',
                        textAlign: 'center',
                      }}
                    />
                  )}
                </Fragment>
              );
            }) || null
          );
        }}
      </FieldArray>
    </>
  );
}
