import { useState } from 'react';
import { PathSwitcher } from '../../components/common/PathSwitcher';
import { PaymentMethod, Subscription } from '../../types';
import { PaymentMethodCreator } from '../../components/paymentMethods/PaymentMethodCreator';
import { CustomerPaymentMethods } from '../../components/paymentMethods/CustomerPaymentMethods';
import { Button, Paragraph } from '@increasecard/typed-components';
import { useUpdateSubscription } from './subscriptionQueries';
import { Row } from '../../components/common/Row';
import { useTranslation } from 'react-i18next';
import { SubscriptionSuccessModal } from './SubscriptionSuccessModal';
import { useHistory } from 'react-router-dom';
import { useModal } from '../../hooks/useModal';
import { ApiErrorMessage } from '../../components/ErrorMessage';
import { Share } from '@styled-icons/material/Share';
export interface SubscriptionPaymentStepProps {
  subscription: Subscription;
}

export function SubscriptionPaymentStep({
  subscription,
}: SubscriptionPaymentStepProps): JSX.Element {
  const { t } = useTranslation();
  const history = useHistory();
  const [createPM, setCreatePM] = useState<boolean>(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<string>();
  const updateSubsription = useUpdateSubscription();
  const [redirect, setRedirect] = useState(true);
  const successModal = useModal();

  const handleExistingPM = () => {
    if (selectedPaymentMethod) {
      updateSubsription.mutate(
        {
          id: subscription.id,
          payment_method_id: selectedPaymentMethod,
        },
        {
          onSuccess: successModal.open,
        }
      );
    }
  };

  const handleModalClose = () => {
    if (redirect) {
      history.replace('/subscriptions');
    } else {
      setRedirect(true);
      successModal.close();
    }
  };

  const handleNewPM = (payment_method: PaymentMethod) => {
    const newSub = {
      ...subscription,
      payment_method,
    };
    successModal.open(newSub);
  };

  return (
    <>
      <Paragraph>
        Elija o cree un metodo de pago para cobrar la factura
      </Paragraph>
      <PathSwitcher
        optionATitle="Elegir un medio de pago existente"
        optionBTitle="Crear nuevo medio de pago"
        value={createPM}
        onChange={setCreatePM}
        style={{ margin: '16px 0' }}
      />
      {createPM ? (
        <PaymentMethodCreator
          onSuccess={handleNewPM}
          customerId={subscription.customer.id}
          groupedCollectMethods={subscription.plan.collect_methods}
          paymentReference={{
            reference_id: subscription.id,
            reference_type: 'subscription',
          }}
        />
      ) : (
        <>
          <CustomerPaymentMethods
            customerId={subscription.customer.id}
            onChange={({ id }) => setSelectedPaymentMethod(id)}
            selectedPaymentMethodId={
              selectedPaymentMethod || subscription.payment_method?.id
            }
            groupedCollectMethods={subscription.plan.collect_methods}
          />
          <ApiErrorMessage error={updateSubsription.error} />
        </>
      )}
      <Row justifyContent="space-between">
        <Row gap="16px" margin="0">
          {!createPM && (
            <Button
              onClick={handleExistingPM}
              disabled={!selectedPaymentMethod}
              isLoading={updateSubsription.isLoading}
            >
              Agregar medio de pago
            </Button>
          )}

          <Button
            data-testid="share-modal-button"
            onClick={() => {
              setRedirect(false);
              successModal.open(subscription);
            }}
            buttonType="invisible"
            disabled={updateSubsription.isLoading}
            icon={<Share />}
          >
            Compartir link de pago
          </Button>
        </Row>
        <Button
          data-testid="exit-button"
          onClick={() => successModal.open(subscription)}
          buttonType="invisible"
          disabled={updateSubsription.isLoading}
        >
          {t('common.exit')}
        </Button>
      </Row>
      <SubscriptionSuccessModal
        onClose={handleModalClose}
        visible={successModal.isOpen}
        subscription={successModal.data}
        isEditing={false}
      />
    </>
  );
}
