import { useTranslation } from 'react-i18next';
import { useCollectMethodById } from '../../globalQueries/Queries';
import { PaymentMethod } from '../../types/PaymentMethod';
import { DetailsBox, RowDefinition } from '../common/DetailsBox';
import { PaymentMethodLabel } from './PaymentMethodLabel';
import { ModeEdit } from '@styled-icons/material/ModeEdit';
import { Button } from '@increasecard/typed-components';

export interface PaymentMethodDetailProps {
  paymentMethod: PaymentMethod | undefined | null;
  onChangeClick?: VoidFunction;
  buttonDisabled?: boolean;
}

export function PaymentMethodDetailBox({
  paymentMethod,
  onChangeClick,
  buttonDisabled,
}: PaymentMethodDetailProps): JSX.Element {
  const { t } = useTranslation();
  const { data: collectMethod } = useCollectMethodById(
    paymentMethod?.collect_method_id
  );

  const getExpirationDate = () => {
    if (!paymentMethod) {
      return '';
    }

    const { expiration_month, expiration_year, expiration_date } =
      paymentMethod.data;
    return expiration_month
      ? `${expiration_month}/${expiration_year}`
      : expiration_date;
  };
  const rowsConfig: RowDefinition[] = [
    {
      label: t('common.number'),
      type: 'custom',
      field: 'paymentMethod',
      // eslint-disable-next-line react/display-name
      valueGetter: (paymentMethod) => {
        return <PaymentMethodLabel paymentMethod={paymentMethod} />;
      },
    },
    {
      label: 'Vencimiento',
      type: 'custom',
      field: 'payment_method',
      valueGetter: getExpirationDate,
    },
    {
      label: 'Titular',
      type: 'string',
      field: 'payment_method.cardholder_name',
      valueGetter: (paymentMethod) => {
        if (!paymentMethod) {
          return '';
        }
        return paymentMethod.data.cardholder_name;
      },
    },
    {
      label: t('common.collect_method'),
      type: 'string',
      field: 'payment_method.collect_method_id',
      valueGetter: () => collectMethod?.name,
    },
  ];
  return (
    <DetailsBox
      className="grid-span-desktop-5 grid-span-mobile-2"
      data={paymentMethod}
      title={t('common.payment_method')}
      rowsConfig={rowsConfig}
      valueAlignment="right"
      icon={
        onChangeClick && (
          <Button
            variant="invisible"
            onClick={onChangeClick}
            icon={<ModeEdit />}
            disabled={buttonDisabled}
          />
        )
      }
    />
  );
}
