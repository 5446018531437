import { Button, ModalFooter } from '@increasecard/typed-components';
import { useTranslation } from 'react-i18next';

export interface MassiveActionFooterProps {
  onClose: VoidFunction;
  onConfirm: VoidFunction;
  isError: boolean;
  isSuccess: boolean;
  isLoading: boolean;
  confirmLabel: string;
}

export function MassiveActionFooter({
  onClose,
  onConfirm,
  isError,
  isSuccess,
  isLoading,
  confirmLabel,
}: MassiveActionFooterProps): JSX.Element {
  const { t } = useTranslation();
  return (
    <ModalFooter align="center">
      {isError || isSuccess ? (
        <Button key="close" onClick={onClose} buttonType="primary">
          {t('common.close')}
        </Button>
      ) : (
        <>
          <Button
            key="cancel"
            data-testid="cancel-button"
            onClick={onClose}
            buttonType="invisible"
          >
            {t('common.cancel')}
          </Button>
          <Button
            isLoading={isLoading}
            data-testid="confirm-button"
            onClick={onConfirm}
          >
            {confirmLabel}
          </Button>
        </>
      )}
    </ModalFooter>
  );
}
