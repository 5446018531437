import { CustomersList } from './components/CustomersList';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Header } from '../../components/common/Header';
import { PayAuthorizations } from '../authorizations/PayAuthorizations';
import { VerticalNav } from '../../components/common/VerticalNav';
import { PayAccessControl } from '../authorizations/PayAccessControl';
import ImportCustomersScreen from './ImportCustomersScreen';

export function CustomersScreen(): JSX.Element {
  const { t } = useTranslation();
  const history = useHistory();

  const handleNewClick = () => {
    history.push('/customers/new');
  };

  const SCREEN_ELEMENTS = [
    {
      path: '',
      title: t('screens.customers.title'),
      content: (
        <>
          <Header
            title={t('screens.customers.title')}
            primaryButton={{
              text: t('common.new_customer'),
              onClick: handleNewClick,
              allowedAuthorization: PayAuthorizations.createEditCustomer,
            }}
          />
          <CustomersList />
        </>
      ),
    },
    {
      path: '/import',
      title: t('screens.import.generic_link'),
      content: (
        <PayAccessControl allowed={PayAuthorizations.createBulkCustomers}>
          <ImportCustomersScreen />
        </PayAccessControl>
      ),
    },
  ];

  return <VerticalNav elements={SCREEN_ELEMENTS} />;
}
