import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { MenuAction } from '../../components/common/Menu';
import { useClipboard } from '../../hooks/useClipboard';
import { ModalControls, useModal } from '../../hooks/useModal';
import { useUIEventTracker } from '../../hooks/useUIEventTracker';
import { Invoice, InvoiceBulkAction, InvoiceStatus } from '../../types/Invoice';
import { usePayAuthorizations } from '../authorizations/usePayAuthorizations';

type InvoiceActionsConfig = MenuAction<InvoiceAction>[];
export interface UseInvoiceActions {
  actions: InvoiceActionsConfig;
  goToDetails: (invoiceId: string, clickContext: string) => void;
  actionsModal: ModalControls<ActionPayload>;
  paymentMethodModal: ModalControls<Invoice>;
}

export type InvoiceAction =
  | InvoiceBulkAction
  | 'view_details'
  | 'copy_checkout_link'
  | 'add_payment_method';

export type ActionPayload = {
  type: InvoiceBulkAction;
  invoices: Invoice[];
};

const RETRYABLE_STATUSES: InvoiceStatus[] = ['open', 'uncollectible'];

export function useInvoiceActions(
  trackingContext: string,
  omit?: InvoiceAction[]
): UseInvoiceActions {
  const { t } = useTranslation();
  const history = useHistory();
  const { canManageInvoices, canRetryPayment } = usePayAuthorizations();
  const clipboard = useClipboard();
  const trackEvent = useUIEventTracker(trackingContext);
  const actionsModal = useModal<ActionPayload>(false);
  const paymentMethodModal = useModal<Invoice>(false);

  const goToDetails = (invoiceId: string, clickContext: string) => {
    trackEvent('view_invoice_detail', { context: clickContext });
    history.push(`/invoices/${invoiceId}/detail`);
  };

  const availableActions: InvoiceActionsConfig = [
    {
      name: 'view_details',
      label: t('screens.invoices.detail'),
      cb: (invoice: Invoice) => goToDetails(invoice.id, 'list row menu'),
      disabled: () => false,
    },
    {
      name: 'retry',
      label: t('common.retry_payment'),
      cb: (invoice: Invoice) => {
        trackEvent('retry_invoice');
        actionsModal.open({
          type: 'retry',
          invoices: Array.isArray(invoice) ? invoice : [invoice],
        });
      },
      disabled: (invoice: Invoice) => {
        // check if this payment can be retried
        if (!canRetryPayment()) {
          // retry denied for this user
          return true;
        }
        if (
          !RETRYABLE_STATUSES.includes(invoice.status) ||
          invoice.payments.some((p) => p.status !== 'failed')
        ) {
          // denied by status
          return true;
        }
        // payment can be retried
        return false;
      },
    },
    {
      name: 'cancel',
      label: t('screens.invoices.cancel'),
      variant: 'alert',
      cb: (invoice: Invoice) => {
        trackEvent('cancel_invoice');
        actionsModal.open({
          type: 'cancel',
          invoices: Array.isArray(invoice) ? invoice : [invoice],
        });
      },
      disabled: (invoice: Invoice) =>
        !canManageInvoices() || !invoice.may_cancel,
    },
    {
      name: 'manual_paid',
      label: t('screens.invoices.manual_paid_label'),
      variant: 'alert',
      cb: (invoice: Invoice) => {
        trackEvent('set_invoice_as_paid');
        actionsModal.open({
          type: 'manual_paid',
          invoices: Array.isArray(invoice) ? invoice : [invoice],
        });
      },
      disabled: (invoice: Invoice) =>
        !canManageInvoices() || !invoice.may_manually_paid,
    },
    {
      name: 'add_payment_method',
      label: t('common.add_payment_method'),
      cb: (invoice: Invoice) => {
        trackEvent('add_invoice_payment_method');
        paymentMethodModal.open(Array.isArray(invoice) ? invoice[0] : invoice);
      },
      disabled: (invoice: Invoice) => !invoice.may_use_checkout_link,
    },
    {
      name: 'payment_method_request',
      label: t('common.ask_payment_method'),
      cb: (invoice: Invoice) => {
        trackEvent('ask_invoice_payment_method');
        actionsModal.open({
          type: 'payment_method_request',
          invoices: Array.isArray(invoice) ? invoice : [invoice],
        });
      },
      disabled: (invoice: Invoice) =>
        !invoice.may_use_checkout_link || !!invoice.latest_payment_method,
    },
    {
      name: 'copy_checkout_link',
      tooltipLabel: t('common.copied'),
      label: t('screens.invoices.payment_link'),
      cb: (invoice: Invoice) => {
        trackEvent('copy_invoice_payment_link');
        clipboard.copy(invoice.checkout_link);
      },
      disabled: (invoice: Invoice) => !invoice.may_use_checkout_link,
    },
  ];

  const actions = omit
    ? availableActions.filter((action) => {
        return !omit.includes(action.name as InvoiceAction);
      })
    : availableActions;

  return { actions, goToDetails, actionsModal, paymentMethodModal };
}
