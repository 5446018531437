import styled from 'styled-components';
import {
  VerticalNavigation,
  VerticalNavigationElement,
} from '@increasecard/typed-components';
import { Link, useRouteMatch, Switch, Route } from 'react-router-dom';
import { DualLayout } from '../../layout/Layout';

// Restitute some styles lost by rendering as Link
const StyledLink = styled(Link)`
  display: inline-flex;
  align-items: center;
  transition: all 200ms ease 0s;
`;

export interface CustomLinkProps {
  label: string;
  to: string;
  activeOnlyWhenExact?: boolean;
}

const StickyNavigation = styled(VerticalNavigation)`
  height: auto;
  width: 100%;
  position: sticky;
  top: 88px;
  gap: 16px;
`;

function CustomLink({
  label,
  to,
  activeOnlyWhenExact,
}: CustomLinkProps): JSX.Element {
  const match = useRouteMatch({
    path: to,
    exact: activeOnlyWhenExact,
  });
  return (
    <VerticalNavigationElement
      active={match}
      as={StyledLink}
      to={to}
      responsive
    >
      {label}
    </VerticalNavigationElement>
  );
}

export interface VerticalNavElement {
  title: string;
  path: string;
  absolute?: boolean;
  content: JSX.Element;
  hide?: boolean;
}

export interface VerticalNavProps {
  elements: VerticalNavElement[];
}

function Menu({
  elements,
  url,
}: {
  elements: VerticalNavElement[];
  url: string;
}): JSX.Element {
  const items = elements.map(({ title, path, absolute, hide }) => {
    if (hide) return null;
    return (
      <CustomLink
        activeOnlyWhenExact={true}
        to={`${absolute ? '' : url}${path}`}
        key={title}
        label={title}
      />
    );
  });
  return (
    <StickyNavigation responsive justifyContent="center">
      {items}
    </StickyNavigation>
  );
}

export function VerticalNav({ elements }: VerticalNavProps): JSX.Element {
  const { path: basePath, url } = useRouteMatch<{
    path?: string;
    url?: string;
  }>();

  return (
    <DualLayout
      leftContext={<Menu elements={elements} url={url} />}
      rightContext={
        <Switch>
          {elements.map(({ path, content, absolute, hide }) => {
            if (hide) return null;
            return (
              <Route
                exact
                key={path}
                path={`${absolute ? '' : basePath}${path}`}
                render={() => content}
              />
            );
          })}
        </Switch>
      }
    />
  );
}
