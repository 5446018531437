import styled from 'styled-components';
import { Button } from '@increasecard/typed-components';
import { FilterAlt } from '@styled-icons/material-outlined/FilterAlt';
import { FilterTags } from './FilterTags';
import { FilterMenu } from './FilterMenu';
import { Row } from '../common/Row';
import { FilterStateProvider } from './FilterContext';
import { ReactNode } from 'react';
import { FilterableFields, FilterFormConfig, FiltersData } from './types';

const FilterButtonTrigger = styled(Button)<{ active?: boolean }>`
  ${({ active }) => active && `background: var(--color-primaryAction-alpha);`}
`;

export interface FilterControlProps {
  rightSideButton?: ReactNode;
  defaultValues: FiltersData;
  config: FilterFormConfig[];
  storageKey: string;
  dateFields?: FilterableFields[];
}

export function FilterControl({
  rightSideButton,
  defaultValues,
  config,
  storageKey,
  dateFields,
}: FilterControlProps): JSX.Element {
  return (
    <div>
      <FilterStateProvider
        defaultValues={defaultValues}
        config={config}
        storageKey={storageKey}
        dateFields={dateFields}
      >
        <Row margin="0" justifyContent="space-between">
          <FilterMenu
            renderTrigger={(props, { toggleShowMenu, showMenu }) => (
              <FilterButtonTrigger
                {...props}
                active={showMenu}
                buttonType="invisible"
                icon={<FilterAlt />}
                onClick={toggleShowMenu}
              >
                Filtrar
              </FilterButtonTrigger>
            )}
          />
          {rightSideButton}
        </Row>
        <FilterTags />
      </FilterStateProvider>
    </div>
  );
}
