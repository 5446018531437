import { ArrowDownward } from '@styled-icons/material/ArrowDownward';
import { useTranslation } from 'react-i18next';
import { useModal } from '../../hooks/useModal';
import { ExportModal } from './ExportModal/ExportModal';
import { Button } from '@increasecard/typed-components';

export interface ExportButtonProps {
  path: string;
  pathFilters: Metadata;
  className?: string;
  buttonType?: 'invisible' | 'outline';
  showIcon?: boolean;
  exportName?: string;
  style?: React.CSSProperties;
}

export function ExportButton({
  path,
  pathFilters,
  className,
  buttonType = 'invisible',
  showIcon = true,
  exportName,
  style,
}: ExportButtonProps): JSX.Element {
  const { t } = useTranslation();
  const exportModal = useModal();

  const openExportModal = () => exportModal.open();
  const onExportModalClose = () => exportModal.close();

  const icon = showIcon ? <ArrowDownward /> : null;

  const buttonName = t(exportName || 'common.export');

  return (
    <>
      <Button
        className={className}
        buttonType={buttonType}
        icon={icon}
        style={style}
        onClick={openExportModal}
      >
        {buttonName}
      </Button>
      {exportModal.isOpen && (
        <ExportModal
          path={path}
          pathFilters={pathFilters}
          isOpen={exportModal.isOpen}
          onClose={onExportModalClose}
        />
      )}
    </>
  );
}
