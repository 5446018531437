import { ReactNode, Suspense } from 'react';
import styled from 'styled-components';
import { Grid } from '@increasecard/typed-components';
import { FullPageSpinner } from '../components/common/FullPageSpinner';

const Layout = styled.div<{ dualLayout?: boolean }>`
  padding: 2rem 1rem 6rem 1rem;
  position: relative;
  background-color: var(--color-gray-graySLight);
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: 2rem 2rem
      ${({ dualLayout }) => (dualLayout ? '2rem 0' : '6rem 1rem')};
  }
`;

const MenuWrapper = styled.div`
  background: #ffffff;
  box-shadow: ${({ theme }) => theme.shadows.elevateCast};
  min-width: 100px;
  align-items: center;
  padding: 1rem;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    /* remove the total height of the top bars */
    min-height: calc(100vh - 112px);
    padding-left: 1.5rem;
  }
`;

const MainGrid = styled(Grid)`
  background-color: var(--color-gray-graySLight);
`;

export interface SingleLayoutProps {
  children: ReactNode;
}

export function SingleLayout({ children }: SingleLayoutProps): JSX.Element {
  return (
    <MainGrid>
      <Layout
        id="main-layout"
        className="grid-span-desktop-10 grid-start-desktop-2 grid-span-mobile-2"
      >
        <Suspense fallback={<FullPageSpinner height="calc(100vh - 96px)" />}>
          {children}
        </Suspense>
      </Layout>
    </MainGrid>
  );
}

export interface DualLayoutProps {
  leftContext: JSX.Element;
  rightContext: JSX.Element;
}

export function DualLayout({
  leftContext,
  rightContext,
}: DualLayoutProps): JSX.Element {
  return (
    <MainGrid>
      <Suspense fallback={<FullPageSpinner />}>
        <MenuWrapper
          id="left-nav-bar"
          className="menu-wrapper grid-span-desktop-2 grid-span-mobile-2"
        >
          {leftContext}
        </MenuWrapper>
        <Layout
          id="main-layout"
          className="grid-span-desktop-10 grid-span-mobile-2"
          dualLayout
        >
          <Suspense fallback={<FullPageSpinner />}>{rightContext}</Suspense>
        </Layout>
      </Suspense>
    </MainGrid>
  );
}
