import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PaymentMethodForm } from './PaymentMethodForm';
import { TabGroup } from '../common/Tabs/TabGroup';
import { Tab } from '../common/Tabs/Tab';
import { Row } from '../common/Row';
import { ErrorMessage } from '../ErrorMessage';
import { Country } from '../../types/common';
import {
  CollectMethodPaymentType,
  GroupedCollectMethods,
} from '../../types/CollectMethod';
import { AccountBrandingSettings } from '../../types/Account';
import { CardProcessorLogo } from './CardProcessorLogo';
import { NewApiError } from '../../types/NewApiError';

export interface PaymentMethodsProps {
  processing: boolean;
  onNext: (data: Metadata) => void;
  onCancel?: () => void;
  submitText?: string;
  cancelText?: string;
  customerId: string;
  country: Country;
  groupedCollectMethods: GroupedCollectMethods;
  planCollectMethods?: GroupedCollectMethods;
  isUniquePayment?: boolean;
  brandingSettings?: AccountBrandingSettings;
  errorObj: NewApiError | null;
}

const tabOrder: CollectMethodPaymentType[] = ['card', 'cbu', 'ticket'];

export function PaymentMethods({
  onNext,
  onCancel,
  processing,
  submitText,
  cancelText,
  country,
  groupedCollectMethods,
  planCollectMethods,
  customerId,
  isUniquePayment,
  brandingSettings,
  errorObj,
}: PaymentMethodsProps): JSX.Element {
  const { t } = useTranslation();
  submitText = submitText ? submitText : t('common.continue');

  const planCM = planCollectMethods
    ? Object.values(planCollectMethods)
        .flat(1)
        .map((cm) => cm.id)
    : [];

  const paymentTypes = tabOrder.filter(
    (paymentType) => groupedCollectMethods[paymentType].length > 0
  );

  const [selectedPaymentType, setSelectedPaymentType] =
    useState<CollectMethodPaymentType>(paymentTypes[0]);

  const handlePaymentChange = (type: CollectMethodPaymentType) => {
    setSelectedPaymentType(type);
    // TODO: Reset error state on payment type change
  };

  const Tabs = (): JSX.Element => {
    return (
      <>
        {paymentTypes.map((type) => {
          return (
            <Tab
              key={type}
              active={selectedPaymentType === type}
              onClick={() => handlePaymentChange(type)}
            >
              {t(`data.payment.methods.${type}`)}
            </Tab>
          );
        })}
      </>
    );
  };

  // No types, no collect methods
  if (paymentTypes.length === 0) {
    return (
      <ErrorMessage>
        {t('data.payment_method.missing_payment_method')}
      </ErrorMessage>
    );
  }
  return (
    <>
      <TabGroup>
        <Tabs />
      </TabGroup>
      {paymentTypes.map((paymentType) => {
        const formClass = selectedPaymentType === paymentType ? 'flex' : 'none';
        // Ugly way of passing props only to the Forms that support them
        const extraProps = paymentType === 'card' ? { isUniquePayment } : {};
        const [currentCM] = groupedCollectMethods[paymentType];
        const showSetAsDefaultPM = currentCM
          ? planCM.includes(currentCM.id)
          : false;

        return currentCM ? (
          <Row
            id={paymentType}
            gap="2rem"
            margin="0"
            alignItems="flex-start"
            key={paymentType}
            display={formClass}
          >
            <PaymentMethodForm
              collectMethods={currentCM}
              onSubmit={onNext}
              onCancel={onCancel}
              processing={processing}
              submitText={submitText}
              cancelText={cancelText}
              country={country}
              customerId={customerId}
              termsAndConditionsUrl={brandingSettings?.terms_and_conditions}
              errorObj={errorObj}
              showSetAsDefaultPM={showSetAsDefaultPM}
              {...extraProps}
            />
          </Row>
        ) : null;
      })}
      <CardProcessorLogo
        collectMethod={
          groupedCollectMethods
            ? groupedCollectMethods[selectedPaymentType][0]
            : undefined
        }
      />
    </>
  );
}
