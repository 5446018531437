import { lazy } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SubscriptionsList } from './SubscriptionsList';
import { Header } from '../../components/common/Header';
import { PayAuthorizations } from '../authorizations/PayAuthorizations';
import { VerticalNav } from '../../components/common/VerticalNav';
import { PayAccessControl } from '../authorizations/PayAccessControl';
const ImportSubscriptionsScreen = lazy(
  () => import('./ImportSubscriptionsScreen')
);
const ImportVariableSubscriptionsScreen = lazy(
  () =>
    import('./ImportVariableSubscriptions/ImportVariableSubscriptionsScreen')
);

export function SubscriptionsScreen(): JSX.Element {
  const { t } = useTranslation();
  const history = useHistory();

  const onNewClick = () => {
    history.push('/subscriptions/new');
  };

  const SCREEN_ELEMENTS = [
    {
      path: '',
      title: t('screens.subscriptions.title'),
      content: (
        <>
          <Header
            title={t('screens.subscriptions.title')}
            primaryButton={{
              text: t('screens.subscriptions.new_subscription'),
              onClick: onNewClick,
              allowedAuthorization: PayAuthorizations.createSubscription,
            }}
          />
          <SubscriptionsList />
        </>
      ),
    },
    {
      path: '/import',
      title: t('screens.import.generic_link'),
      content: (
        <PayAccessControl allowed={PayAuthorizations.createBulkSubscriptions}>
          <ImportSubscriptionsScreen />
        </PayAccessControl>
      ),
    },
    {
      path: '/update-variable',
      title: t('screens.import_variable_subscriptions.link'),
      content: (
        <PayAccessControl allowed={PayAuthorizations.updateBulkSubscriptions}>
          <ImportVariableSubscriptionsScreen />
        </PayAccessControl>
      ),
    },
  ];

  return <VerticalNav elements={SCREEN_ELEMENTS} />;
}
