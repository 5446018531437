import { Tag } from '@increasecard/typed-components';
import { Row } from './Row';

export interface TagListProps {
  tags: string[];
  onClose?: (t: string) => void;
}

const colors = {
  background: (): string => 'var(--color-pay-light)',
  foreground: (): string => 'var(--color-pay-dark)',
  border: (): string => 'var(--color-gray-subtleBorder)',
};

export function TagList({ tags, onClose }: TagListProps): JSX.Element | null {
  if (tags.length === 0) return null;

  return (
    <Row margin="0" gap="0.5rem" flexWrap="wrap">
      {tags.map((tag) => {
        return (
          <Tag
            key={tag}
            colors={colors}
            onClose={onClose ? () => onClose(tag) : null}
          >
            {tag}
          </Tag>
        );
      })}
    </Row>
  );
}
