import { useEffect } from 'react';
import { loadScript } from '../utils/utils';
import { environment } from '../config';

export function useLoadAppcues(): void {
  useEffect(() => {
    if (environment === 'production' || environment === 'sandbox') {
      loadScript('//fast.appcues.com/26340.js', 'appcues');
    }
  }, []);
}
