import { CollectMethod } from '../../types/CollectMethod';
import {
  PCI,
  MercadoPagoLogo,
  PayU,
  Decidir,
  dLocal as Dlocal,
} from '@increasecard/icons';
import { Row } from '../common/Row';
import { Label } from '@increasecard/typed-components';
import { useTranslation } from 'react-i18next';
import { environment } from '../../config';

export interface CardProcessorLogoProps {
  collectMethod?: CollectMethod;
}

export function CardProcessorLogo({
  collectMethod,
}: CardProcessorLogoProps): JSX.Element | null {
  const { t } = useTranslation();
  if (!collectMethod || !collectMethod.payment_categories.includes('card')) {
    return null;
  }

  const getIcon = (collectMethod: CollectMethod) => {
    switch (collectMethod.type) {
      case 'payments_os':
        return collectMethod.metadata.provider_name === 'PayULatam' ? (
          <PayU height="40px" />
        ) : null;
      case 'decidir':
        return <Decidir height="40px" />;
      case 'dlocal':
      case 'dlocal_cross':
        return <Dlocal height="35px" />;
      case 'mercadopago_connect':
        return environment !== 'sandbox' ? (
          <MercadoPagoLogo height="40px" />
        ) : null;
      default:
        return null;
    }
  };

  return (
    <Row gap="1rem" margin="0">
      {environment !== 'sandbox' && (
        <Label>{t('screens.customer_checkout.processed_by')}</Label>
      )}
      {getIcon(collectMethod)}
      <PCI height="38px" />
    </Row>
  );
}
