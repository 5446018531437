import styled from 'styled-components';
import {
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeaderIconBox,
  Button,
  Paragraph,
  InputText,
  H2,
  Link,
} from '@increasecard/typed-components';
import { Check } from '@styled-icons/material/Check';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Subscription } from '../../types/Subscription';
import { CheckoutLinkSharingWidget } from '../../components/ShareWidget/CheckoutLinkSharingWidget';
import { CopyButton } from '../../components/common/CopyButton';
import { useModal } from '../../hooks/useModal';
import { ActionsModal } from './components/ActionsModal';

const NEW_SUBSCRIPTION_SUCCESS_MODAL_WIDTH = '569';

const noop = () => {
  return;
};

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  margin: 1rem 0px 1.5rem 0px;
`;

export interface SubscriptionSuccessModalProps {
  visible: boolean;
  onClose: () => void;
  subscription: Subscription;
  isEditing: boolean;
}

export function SubscriptionSuccessModal({
  visible,
  onClose,
  subscription,
  isEditing,
}: SubscriptionSuccessModalProps): JSX.Element {
  const { t } = useTranslation();
  const history = useHistory();
  const notificationModal = useModal();
  const checkoutLink = subscription?.checkout_link;
  const paymentMethod = subscription?.payment_method;
  const customerId = subscription?.customer?.id;

  const goToSubscriptionDetail = (id: string) => {
    history.push(`/subscriptions/${id}/detail`);
  };

  return (
    <>
      <Modal
        align="center"
        onClose={onClose}
        visible={visible}
        width={NEW_SUBSCRIPTION_SUCCESS_MODAL_WIDTH}
        onOk={noop}
        onCancel={noop}
      >
        <ModalHeaderIconBox colorPalette="pay" icon={<Check size="48px" />} />
        <ModalContent align="center">
          <H2>
            {t('screens.new_subscription.success' + (isEditing ? '_edit' : ''))}
          </H2>
          {!paymentMethod && (
            <>
              <Trans key="screens.new_subscription.success_description" t={t} />
              <StyledDiv>
                <InputText
                  id="link"
                  type="text"
                  label={t('screens.new_subscription.subscription_page')}
                  value={checkoutLink}
                />
                <CopyButton
                  value={checkoutLink}
                  buttonProps={{ style: { margin: '1rem 0.5rem 0 0.5rem' } }}
                />
              </StyledDiv>
              <CheckoutLinkSharingWidget
                customerId={customerId}
                checkoutLink={checkoutLink}
                checkoutType="subscription"
                subscription={subscription}
              />
            </>
          )}
          {!isEditing && (
            <Button
              variant="outline"
              style={{ margin: '16px 0' }}
              size="small"
              onClick={notificationModal.open}
            >
              Enviar notificación vía mail
            </Button>
          )}
          <Paragraph>
            {t('screens.new_subscription.success_description_created')}
            <Link onClick={() => goToSubscriptionDetail(subscription?.id)}>
              aqui
            </Link>
          </Paragraph>
        </ModalContent>
        <ModalFooter align="center">
          <Button key="back" onClick={onClose} buttonType="primary">
            {t('common.accept')}
          </Button>
        </ModalFooter>
      </Modal>
      <ActionsModal
        visible={notificationModal.isOpen}
        subscription={[subscription]}
        onClose={notificationModal.close}
        actionType="ask_payment_method"
      />
    </>
  );
}
